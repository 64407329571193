<template>
  <div class="cx-text2 hide">
    <p class="title title1">
      <span>{{$t('home.title1')}}</span>
      <span class="bold">{{$t('home.title2')}}</span>
      <span>{{$t('home.title3')}}</span>
      <span class="bold">{{$t('home.title4')}}</span>
    </p>
    <p class="title title2">
      <span>{{$t('home.title5')}}</span>
    </p>
    <scroll-bottom class="scrollBox"></scroll-bottom>
  </div>
</template>
<script>
import scrollBottom from './scrollBottom.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  components: {scrollBottom}
}
</script>
<style scoped lang="stylus">
.cx-text2
  position absolute
  top 0
  left 0
  width 100vw
  height 100vh
  display flex
  justify-content center
  flex-direction column
  overflow hidden
  z-index 3
  .title
    text-align center
    line-height 60px
    font-size 46px
    width 80%
    margin 0 auto 44px auto
    color $c7
    .bold
      color #00FFC2
      fontWeight()
.show
  display flex
  .scrollBox
    opacity 1
  .title1, .title2
    opacity 0
  .title1
    animation textUp 1s 0.7s ease forwards
  .title2
    animation textUp 1s 1s ease forwards
.hide
  .title1, .title2
    opacity 0
.hideUp, .hideDown
  .title1, .title2
    opacity 1
  .scrollBox
    opacity 0
.hideUp
  .title1
    animation hideUp1 .6s 0s ease forwards
  .title2
    animation hideUp2 .6s 0s ease forwards
.hideDown
  .title1
    animation hideDown1 .6s 0s ease forwards
  .title2
    animation hideDown2 .6s 0s ease forwards

@keyframes hideUp1
  0%
    opacity 1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 500%, 0)
@keyframes hideUp2
  0%
    opacity 0.5
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 500%, 0)

@keyframes hideDown1
  0%
    opacity 0.5
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -500%, 0)
@keyframes hideDown2
  0%
    opacity 1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -500%, 0)
@media screen and (max-width: 1440px)
  .cx-text2
    .title
      font-size 32px
@media screen and (max-width: 800px)
  .cx-text2
    .title
      font-size 1rem
      line-height 1.5rem
</style>