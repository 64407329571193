<template>
  <div class="cx-rotate-title"> 
    <span class="cx-font1" v-for="(item, index) in str" :key="'str'+index" :ref="'str'+index" >{{item}}</span> 
    <div class="hoverBox" @mouseover="hover" @click="click"></div>  
  </div>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
export default {
  data () {
    return {
      isHover: false,
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }, 
  },
  computed: {
    str () {
      return this.title.split('')
    }
  },
  mounted () {
  },
  methods: {
    hover () {
      if (!this.isHover) {
        this.isHover = true
        this.str.map((val, index) => {
          anime({
            targets: this.$refs['str'+index][0],
            rotateX: -360,
            duration: 800,
            easing: 'easeInOutQuad',
            delay: index * 50
          })
        })
        setTimeout(() => {
          this.isHover = false
          this.str.map((val, index) => {
            this.$refs['str' + index][0].style = `transform: rotateX(0);`
          })
        }, 800 + this.title.length * 70)
      }
    },
    click () {
      this.$emit('click')
    },
  },
  beforeDestroy () {
  },
  components: {}
}
</script>
<style scoped lang="stylus">
.cx-rotate-title
  cursor pointer
  position relative
  .cx-font1
    position relative
    display inline-block 
  .hoverBox
    position absolute
    left 0
    top 0
    width 100%
    height 100%
</style>