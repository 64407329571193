<template>
  <div class="cx-text3 hide">
    <flow-title class="flowTitle1" :title="$t('product.title1')" :size="pageWidth < 600 ? 28:46"></flow-title>
    <p class="text1">{{$t('product.text1')}}</p>
    <p class="text2">
      <label>{{$t('product.title2')}}</label>
      <span>{{$t('product.titleTip2')}}</span>
    </p>
    <div class="list">
      <p class="item"><img class="dot" src="@assets/images/tab.svg"><span>{{$t('product.t1')}}</span></p>
      <p class="item"><img class="dot" src="@assets/images/tab.svg"><span>{{$t('product.t2')}}</span></p>
      <p class="item"><img class="dot" src="@assets/images/tab.svg"><span>{{$t('product.t3')}}</span></p>
      <p class="item"><img class="dot" src="@assets/images/tab.svg"><span>{{$t('product.t4')}}</span></p>
      <p class="item"><img class="dot" src="@assets/images/tab.svg"><span>{{$t('product.t5')}}</span></p>
      <p class="item"><img class="dot" src="@assets/images/tab.svg"><span>{{$t('product.t6')}}</span></p>
    </div>
  </div>
</template>
<script>
import flowTitle from '@/components/base/flow-title.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  components: {flowTitle}
}
</script>
<style scoped lang="stylus">
.cx-text3
  position absolute
  top 0
  left 10vw
  width 43vw
  height 100vh
  display flex
  justify-content center
  flex-direction column
  overflow hidden
  z-index 3
  .text1
    font-size 24px
    color $c7
    line-height 36px
    margin 50px 0 80px 0
  .text2
    margin-bottom 24px
    label
      font-size 32px
      color #00FFC2
      margin-right 16px
      fontWeight()
    span
      font-size 20px
      color $c7
  .list
    display flex
    flex-wrap wrap
    .item
      margin-top 24px
      color #CCCCCC
      font-size 24px
      line-height 44px
      width 50%
      display flex
      .dot
        width 16px
        margin-right 20px
.show
  display flex
  .flowTitle1, .text1, .text2, .list .item
    opacity 0
  .flowTitle1
    animation textUp 0.8s 2s ease forwards
  .text1
    animation textUp 0.8s 2.2s ease forwards
  .text2
    animation textUp 0.8s 2.4s ease forwards
  .list
    .item
      &:nth-of-type(1), &:nth-of-type(2)
        animation textUp 0.8s 2.6s ease forwards
      &:nth-of-type(3), &:nth-of-type(4)
        animation textUp 0.8s 2.8s ease forwards
      &:nth-of-type(5), &:nth-of-type(6)
        animation textUp 0.8s 3s ease forwards
.hide
  .flowTitle1, .text1, .text2, .list .item
    opacity 0
.hideUp, .hideDown
  .flowTitle1, .text1, .text2, .list .item
    opacity 1
.hideUp
  .flowTitle1
    animation hideUp1 1s 0s ease forwards
  .text1
    animation hideUp2 1s 0s ease forwards
  .text2
    animation hideUp3 1s 0s ease forwards
  .list
    .item
      &:nth-of-type(1), &:nth-of-type(2)
        animation hideUp4 1s 0s ease forwards
      &:nth-of-type(3), &:nth-of-type(4)
        animation hideUp5 1s 0s ease forwards
      &:nth-of-type(5), &:nth-of-type(6)
        animation hideUp6 1s 0s ease forwards
.hideDown
  .flowTitle1
    animation hideDown1 1s 0s ease forwards
  .text1
    animation hideDown2 1s 0s ease forwards
  .text2
    animation hideDown3 1s 0s ease forwards
  .list
    .item
      &:nth-of-type(1), &:nth-of-type(2)
        animation hideDown4 1s 0s ease forwards
      &:nth-of-type(3), &:nth-of-type(4)
        animation hideDown5 1s 0s ease forwards
      &:nth-of-type(5), &:nth-of-type(6)
        animation hideDown6 1s 0s ease forwards


@keyframes hideUp1
  0%
    opacity 1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp2
  0%
    opacity 0.8
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp3
  0%
    opacity 0.6
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp4
  0%
    opacity 0.4
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp5
  0%
    opacity 0.2
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp6
  0%
    opacity 0.1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
    
@keyframes hideDown1
  0%
    opacity 0.1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown2
  0%
    opacity 0.2
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown3
  0%
    opacity 0.4
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown4
  0%
    opacity 0.6
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown5
  0%
    opacity 0.8
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown6
  0%
    opacity 1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@media screen and (max-width: 1440px)
  .cx-text3
    width 56vw
    .text1
      font-size 20px
      line-height 30px
      margin 30px 0 50px 0
    .text2
      label
        font-size 24px
      span
        font-size 16px
    .list
      .item
        font-size 20px
        margin-top 12px
@media screen and (max-width: 800px)
  .cx-text3
    left 5vw
    right 5vw
    width 90vw
    .text1
      font-size 0.7rem
      line-height 1rem
      margin 0.5rem 0 1.5rem 0
    .text2
      label
        font-size 0.75rem
      span
        font-size 0.65rem
    .list
      .item
        width 100%
        font-size 0.7rem
        line-height 1.2rem
        margin-top 0.5rem
        color #FFF
        fontWeight()
        .dot
          margin-right 0.5rem
</style>