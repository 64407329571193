<template>
  <div class="cx-dada-title"> 
    <span class="cx-font2" :style="`color:${color};font-size:${fontSize}px;`" v-for="(item, index) in str" :key="'str'+index" :ref="'str'+index">{{item}}</span>
  </div>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
export default {
  data () {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'rgba(255,255,255,1)'
    },
    fontSize: {
      type: Number,
      default: 20,
    },
    setTime: {
      type: Number,
      default: 200,
    }
  },
  computed: {
    str () {
      return this.title.split('')
    }
  },
  mounted () {
    this.show()
  },
  methods: {
    show () {      
      setTimeout(() => {
        $('.cx-font2').css({opacity: 0})
        this.str.map((val, index) => {
          anime({
            targets: this.$refs['str'+index][0],
            opacity: 1,
            duration: 100,
            easing: 'easeInOutQuad',
            delay: index * 100
          })
        })
      }, this.setTime)
    },
  },
  beforeDestroy () {
  },
  components: {}
}
</script>
<style scoped lang="stylus">
.cx-dada-title
  letter-spacing 1px
  cursor pointer
  position relative
  color rgba(255,255,255,0.8)
  letter-spacing 1px
  .cx-font2
    font-size 14px
    display inline-block
    opacity 0

</style>