<template>
  <div class="cx-scroll-bottom">
    <p class="text">{{$t('home.scrollBottom')}}</p>
    <div class="arrow">
      <div class="dot1">
        <svg xmlns="http://www.w3.org/2000/svg" width="23.752" height="12.668" viewBox="0 0 23.752 12.668">
          <path id="路径_52837" data-name="路径 52837" d="M3,0,14.876,7.917,26.752,0V4.75L14.876,12.668,3,4.75Z" transform="translate(-3)" fill="#ff7318"/>
        </svg>
      </div>
      <div class="dot2">
        <svg xmlns="http://www.w3.org/2000/svg" width="23.752" height="12.668" viewBox="0 0 23.752 12.668">
          <path id="路径_52837" data-name="路径 52837" d="M3,0,14.876,7.917,26.752,0V4.75L14.876,12.668,3,4.75Z" transform="translate(-3)" fill="#ff7318"/>
        </svg>
      </div>
      <div class="dot3">
        <svg xmlns="http://www.w3.org/2000/svg" width="23.752" height="12.668" viewBox="0 0 23.752 12.668">
          <path id="路径_52837" data-name="路径 52837" d="M3,0,14.876,7.917,26.752,0V4.75L14.876,12.668,3,4.75Z" transform="translate(-3)" fill="#ff7318"/>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  components: {}
}
</script>
<style scoped lang="stylus">
.cx-scroll-bottom
  position absolute
  bottom 28px
  text-align center
  width 100%
  .text
    font-size $f14
    color $c5
    margin-bottom 12px
  .arrow
    width 23.75px
    margin 0 auto
    .dot1
      animation dot1 1s 0s steps(3) infinite
    .dot2
      animation dot1 1s 0.12s steps(3) infinite
    .dot3
      animation dot1 1s 0.24s steps(3) infinite
  @keyframes dot1
    0%
      opacity 1
    33%
      opacity 0.6
    66%
      opacity 0.2
    90%
      opacity 0
</style>