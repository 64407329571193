<template>
  <div class="cx-dot6" v-if="index >= 0">
    <svg xmlns="http://www.w3.org/2000/svg" width="63.877" height="63.878" viewBox="0 0 63.877 63.878">
      <path id="减去_3" data-name="减去 3" d="M38-4999.561A32.01,32.01,0,0,1,8.061-5029.5H12.07a27.737,27.737,0,0,0,2.129,8.9,27.921,27.921,0,0,0,6,8.9,27.946,27.946,0,0,0,8.9,6,27.772,27.772,0,0,0,8.9,2.129v4.007Zm4,0h0v-4.007a27.763,27.763,0,0,0,8.9-2.129,27.933,27.933,0,0,0,8.9-6,27.921,27.921,0,0,0,6-8.9,27.779,27.779,0,0,0,2.129-8.9h4.008A32.008,32.008,0,0,1,42-4999.562h0Zm29.94-33.937H67.931a27.779,27.779,0,0,0-2.129-8.9,27.921,27.921,0,0,0-6-8.9,27.931,27.931,0,0,0-8.9-6,27.754,27.754,0,0,0-8.9-2.129v-4.008A32.011,32.011,0,0,1,71.939-5033.5Zm-59.869,0H8.061A32.012,32.012,0,0,1,38-5063.439v4.008a27.781,27.781,0,0,0-8.9,2.129,27.926,27.926,0,0,0-8.9,6,27.921,27.921,0,0,0-6,8.9,27.748,27.748,0,0,0-2.129,8.9Z" transform="translate(-8.061 5063.439)" fill="#fff" opacity="0.4"/>
    </svg>
    <div class="arrow" :class="`rotate${index}`">
      <svg xmlns="http://www.w3.org/2000/svg" width="54.031" height="52.163" viewBox="0 0 54.031 52.163">
        <g id="组_99278" data-name="组 99278" transform="translate(-1323 -851)">
          <g id="组_99276" data-name="组 99276" transform="translate(44 87.163)">
            <path id="联合_2" data-name="联合 2" d="M0-5053.323a8,8,0,0,1,8-8,7.97,7.97,0,0,1,3.076.613l23.955-18.776-19.2,24.5a8.033,8.033,0,0,1,.173,1.661,8,8,0,0,1-8,8A8,8,0,0,1,0-5053.323Z" transform="translate(1298 5843.322)" fill="#ff7318"/>
            <circle id="椭圆_3" data-name="椭圆 3" cx="3" cy="3" r="3" transform="translate(1303 787)" fill="#001"/>
          </g>
          <g id="组_99277" data-name="组 99277" transform="translate(2656.031 1667) rotate(180)" opacity="0">
            <path id="联合_2-2" data-name="联合 2" d="M0-5053.323a8,8,0,0,1,8-8,7.97,7.97,0,0,1,3.076.613l23.955-18.776-19.2,24.5a8.033,8.033,0,0,1,.173,1.661,8,8,0,0,1-8,8A8,8,0,0,1,0-5053.323Z" transform="translate(1298 5843.322)" fill="#ff7318"/>
            <circle id="椭圆_3-2" data-name="椭圆 3" cx="3" cy="3" r="3" transform="translate(1303 787)" fill="#001"/>
          </g>
        </g>
      </svg>
    </div>
    <div class="dot dot0" v-show="index === 0">
      <svg xmlns="http://www.w3.org/2000/svg" width="29.939" height="29.939" viewBox="0 0 29.939 29.939">
        <path id="路径_52855" data-name="路径 52855" d="M71.939-5033.5H67.931a27.779,27.779,0,0,0-2.129-8.9,27.921,27.921,0,0,0-6-8.9,27.931,27.931,0,0,0-8.9-6,27.754,27.754,0,0,0-8.9-2.129v-4.008A32.011,32.011,0,0,1,71.939-5033.5Z" transform="translate(-42 5063.438)" fill="#ff7318"/>
      </svg>
    </div>
    <div class="dot dot1" v-show="index === 1">
      <svg xmlns="http://www.w3.org/2000/svg" width="29.939" height="29.939" viewBox="0 0 29.939 29.939">
        <path id="路径_52855" data-name="路径 52855" d="M71.939-5033.5H67.931a27.779,27.779,0,0,0-2.129-8.9,27.921,27.921,0,0,0-6-8.9,27.931,27.931,0,0,0-8.9-6,27.754,27.754,0,0,0-8.9-2.129v-4.008A32.011,32.011,0,0,1,71.939-5033.5Z" transform="translate(-42 5063.438)" fill="#ff7318"/>
      </svg>
    </div>
    <div class="dot dot2" v-show="index === 2">
      <svg xmlns="http://www.w3.org/2000/svg" width="29.939" height="29.939" viewBox="0 0 29.939 29.939">
        <path id="路径_52855" data-name="路径 52855" d="M71.939-5033.5H67.931a27.779,27.779,0,0,0-2.129-8.9,27.921,27.921,0,0,0-6-8.9,27.931,27.931,0,0,0-8.9-6,27.754,27.754,0,0,0-8.9-2.129v-4.008A32.011,32.011,0,0,1,71.939-5033.5Z" transform="translate(-42 5063.438)" fill="#ff7318"/>
      </svg>
    </div>
    <div class="dot dot3" v-show="index === 3">
      <svg xmlns="http://www.w3.org/2000/svg" width="29.939" height="29.939" viewBox="0 0 29.939 29.939">
        <path id="路径_52855" data-name="路径 52855" d="M71.939-5033.5H67.931a27.779,27.779,0,0,0-2.129-8.9,27.921,27.921,0,0,0-6-8.9,27.931,27.931,0,0,0-8.9-6,27.754,27.754,0,0,0-8.9-2.129v-4.008A32.011,32.011,0,0,1,71.939-5033.5Z" transform="translate(-42 5063.438)" fill="#ff7318"/>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      index: -1,
    }
  },
  mounted () {
  },
  methods: {
    changeName (idx) {
      this.index = idx
    }
  },
  components: {}
}
</script>
<style scoped lang="stylus">
.cx-dot6
  position absolute
  bottom 20px
  left calc(50% - 32px)
  width 64px
  height 64px
  .dot, .arrow
    position absolute
  .dot
    opacity 0
    animation showDot .1s .5s ease forwards
  .dot0
    top 0
    right 0
  .dot1
    bottom 0
    right 0
    transform rotate(90deg)
  .dot2
    bottom 0
    left 0
    transform rotate(180deg)
  .dot3
    left 0
    top 0
    transform rotate(270deg)
  .arrow
    left 50%
    top 50%
    transform translate3d(-50%,-50%,0) rotate(0)
  .rotate0 
    animation rotate0 .6s ease forwards
  .rotate1
    animation rotate1 .6s ease forwards
  .rotate2
    animation rotate2 .6s ease forwards
  .rotate3
    animation rotate3 .6s ease forwards
  @keyframes showDot
    0% 
      opacity 0
    100%
      opacity 1
  @keyframes rotate0
    0% 
      transform translate3d(-50%,-50%,0) rotate(-90deg)
    100%
      transform translate3d(-50%,-50%,0) rotate(0)
  @keyframes rotate1
    0% 
      transform translate3d(-50%,-50%,0) rotate(0)
    100%
      transform translate3d(-50%,-50%,0) rotate(90deg)
  @keyframes rotate2
    0% 
      transform translate3d(-50%,-50%,0) rotate(90deg)
    100%
      transform translate3d(-50%,-50%,0) rotate(180deg)
  @keyframes rotate3
    0% 
      transform translate3d(-50%,-50%,0) rotate(180deg)
    100%
      transform translate3d(-50%,-50%,0) rotate(270deg)
@media screen and (max-width: 600px)
  .cx-dot6
    transform scale(0.6)
    bottom auto
    top 14rem
</style>