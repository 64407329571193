<template>
  <div class="cx_page5_text" ref="cx_page5_text">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  data () {
    return {
      canvas: null,
      ctx: null,
      width: 3600, 
      height: 200,
      fontSize: 50,
      nameList: [
        {name: '', value: 60},
        {name: '', value: 80},
        {name: '', value: 18},
        {name: '', value: 130},
      ],
      index: 0,
      timer: null,
    }
  },
  mounted () {
    this.getData()
    this.init()
    this.timer = setInterval(() => {
      this.$emit("change")
    }, 50)
  }, 
  methods: {
    getData () {
      this.nameList[0].name = this.$t('server.t1')
      this.nameList[1].name = this.$t('server.t2')
      this.nameList[2].name = this.$t('server.t3')
      this.nameList[3].name = this.$t('server.t4')
    },
    changeName (idx) {
      this.index = idx
      if (this.index >= 0) {
        this.drawLine()
        this.drawText()
      }
    },
    init () {
      this.$refs.cx_page5_text.style = `width:${this.width}px;height:${this.height}px;`
      this.canvas = this.$refs.canvas
      this.canvas.width = this.width
      this.canvas.height = this.height
      this.ctx = this.canvas.getContext('2d')
    },
    async drawLine () {
      this.ctx.strokeStyle = '#ffffff'
      this.ctx.lineWidth = 1
      for(let i = 0; i< 28; i++) {
        await new Promise((resolve) => {
          setTimeout(resolve, 20);
        })
        this.ctx.beginPath()
        this.ctx.moveTo(i * 50 + this.width / 1.72, this.height * 0.9)
        this.ctx.lineTo((i + 1) * 50 + this.width / 1.72, this.height * 0.9)
        this.ctx.closePath()
        this.ctx.stroke()
      }
      this.ctx.fillStyle = '#ffffff'
      this.ctx.fillRect(1400 + this.width / 1.72, this.height * 0.9 - 5, 50, 10)
    },
    async drawText () {
      this.ctx.clearRect(0,0,this.width, this.height)
      let text = this.nameList[this.index].name
      this.ctx.font = `${this.fontSize}px '方圆'`
      this.ctx.fillStyle = '#ffffff'
      await this.drawName(text)
      let value = this.nameList[this.index].value
      let nameWidth = this.ctx.measureText(text).width + 10 + this.width / 1.72
      this.ctx.font = `${this.fontSize * 2.2}px '方圆'`
      this.ctx.fillStyle = '#FF2800'
      this.ctx.fillText(value, nameWidth, this.height * 0.75)
      let valueWidth = this.ctx.measureText(value).width + 8 + this.width / 1.72
      this.ctx.font = `${this.fontSize * 1.5}px '方圆'`
      this.ctx.fillStyle = '#FF2800'
      this.ctx.fillText('+', nameWidth + valueWidth - this.width / 1.72, this.height * 0.7)
    },
    async drawName (text) {
      let arr = text.split('')
      for(let i = 0; i< arr.length; i++) {
        await new Promise((resolve) => {
          setTimeout(resolve, 28);
        })
        let width = this.ctx.measureText(text.substring(0, i)).width + 10 + this.width / 1.72
        this.ctx.fillText(arr[i], width, this.height * 0.7)
      }
    },
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  watch: {
    language () {
      this.getData()
    }
  }
}
</script>

<style lang="stylus" scope>
.cx_page5_text
  position absolute 
  display none
  top 0
  left 0
</style>