<template>
  <div class="cx-text1">
    <scroll-bottom></scroll-bottom>
  </div>
</template>
<script>
import scrollBottom from './scrollBottom.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  components: {scrollBottom}
}
</script>
<style scoped lang="stylus">
.cx-text1
  width 100%
</style>