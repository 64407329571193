<template>
  <div class="yy_wave_dot" ref="yy_wave_dot">
    <canvas ref="canvas"></canvas>
  </div>
</template>
<script>
export default {
  data () {
    return {
      width: 1200,
      dotLength: 160,
      dotHeight: 1,
      dotHeight1: 30,
      dotWidth: 1.2,
      color: 'rgba(0,200,255,0.9)',
      color1: 'rgba(255,255,255,0.25)',
      color2: 'rgba(0,50,255,0.6)',
      canvas: null,
      ctx: null,
      dataList: [],
      dataList1: [],
      timer: null,
      radius: null,
      showBlue: false,
    }
  },
  props: {
    dataObj: {
      type: Object,
      default: () => {return {}}
    }
  },
  created () {
    this.setObj()
  },
  mounted () {
    this.init()
    this.timer = setInterval(() => {
      this.change()
      this.$emit("change")
    }, 150)
  }, 
  computed: {
    dotDeg () {
      return 2 * Math.PI / this.dotLength
    },
    er () {
      return 0.73 * this.radius
    },
    er1 () {
      return 0.8 * this.radius
    },
  },
  methods: {
    init () {
      this.$refs.yy_wave_dot.style = `width:${this.width}px;height:${this.width}px;`
      this.canvas = this.$refs.canvas
      this.canvas.width = this.width
      this.canvas.height = this.width
      this.ctx = this.canvas.getContext('2d')
      this.dataList = []
      let d = 2 / this.dotLength * 8
      for(let i = 0; i < this.dotLength; i++) {
        let deg = i * d * Math.PI
        let y = Math.cos(deg) * this.dotHeight
        this.dataList.push({deg, y})
      }
      let d1 = 2 / this.dotLength * 3
      let d2 = 2 / this.dotLength * 2
      let d3 = 2 / this.dotLength * 5
      for(let i = 0; i < this.dotLength * 2; i++) {
        let deg = i * d1 * Math.PI
        let y = Math.cos(deg) * this.dotHeight1
        let deg1 = i * d2 * Math.PI
        let y1 = Math.cos(deg1) * this.dotHeight1
        let deg2 = i * d3 * Math.PI
        let y2 = Math.cos(deg2) * this.dotHeight1
        this.dataList1.push({deg, y, deg1, y1, deg2, y2})
      }
      this.drawLine()
    },
    setObj () {      
      for(let i in this.dataObj) {
        this[i] = this.dataObj[i]
      }
      this.radius = this.width / 2
    },
    moveHeight () {
      if (this.dotHeight < 30) {
        setTimeout(() => {
          this.dotHeight++
          this.change()
          this.$emit("change")
          this.moveHeight()
        }, 100);
      }  
    },
    change () {
      this.dataList.map((val, idx) => {
        val.deg += 0.02*Math.PI
        val.y = Math.cos(val.deg) * this.dotHeight-(30-this.dotHeight)
      })
      this.dataList1.map((val, idx) => {
        val.deg += 0.04*Math.PI
        val.y = Math.cos(val.deg) * this.dotHeight1
        val.deg1 += 0.04*Math.PI
        val.y1 = Math.cos(val.deg1) * this.dotHeight1
        val.deg2 += 0.04*Math.PI
        val.y2 = Math.cos(val.deg2) * this.dotHeight1
      })
      this.ctx.clearRect(0,0,this.width, this.width)
      this.drawLine()
    },
    drawLine () {
      this.ctx.lineWidth = 0.8
      this.ctx.strokeStyle = this.color1
      this.dataList1.map((val, idx) => {
        let ex = this.radius + Math.sin(this.dotDeg / 2 * idx) * (this.er1 + val.y)
        let ey = this.radius + Math.cos(this.dotDeg / 2 * idx) * (this.er1 + val.y)
        this.ctx.lineTo(ex,ey)
      })
      this.ctx.stroke()
      this.dataList1.map((val, idx) => {
        let ex = this.radius + Math.sin(this.dotDeg / 2 * idx) * (this.er1 + val.y1)
        let ey = this.radius + Math.cos(this.dotDeg / 2 * idx) * (this.er1 + val.y1)
        this.ctx.lineTo(ex,ey)
      })
      this.ctx.stroke()
      this.dataList1.map((val, idx) => {
        let ex = this.radius + Math.sin(this.dotDeg / 2 * idx) * (this.er1 + val.y2)
        let ey = this.radius + Math.cos(this.dotDeg / 2 * idx) * (this.er1 + val.y2)
        this.ctx.lineTo(ex,ey)
      })
      this.ctx.stroke()
      this.ctx.fillStyle = 'rgba(255,255,255,0.8)'
      this.dataList1.map((val, idx) => {
        let ex = this.radius + Math.sin(this.dotDeg / 2 * idx) * (this.er1 + val.y)
        let ey = this.radius + Math.cos(this.dotDeg / 2 * idx) * (this.er1 + val.y)
        if (idx % 15 === 1) {
          this.ctx.beginPath()
          this.ctx.arc(ex, ey, 2, 0, 2*Math.PI)
          this.ctx.fill()
          this.ctx.closePath()
        }
      })
      this.dataList1.map((val, idx) => {
        let ex = this.radius + Math.sin(this.dotDeg / 2 * idx) * (this.er1 + val.y1)
        let ey = this.radius + Math.cos(this.dotDeg / 2 * idx) * (this.er1 + val.y1)
        if (idx % 15 === 5) {
          this.ctx.beginPath()
          this.ctx.arc(ex, ey, 2, 0, 2*Math.PI)
          this.ctx.fill()
          this.ctx.closePath()
        }
      })
      this.dataList1.map((val, idx) => {
        let ex = this.radius + Math.sin(this.dotDeg / 2 * idx) * (this.er1 + val.y2)
        let ey = this.radius + Math.cos(this.dotDeg / 2 * idx) * (this.er1 + val.y2)
        if (idx % 15 === 10) {
          this.ctx.beginPath()
          this.ctx.arc(ex, ey, 2, 0, 2*Math.PI)
          this.ctx.fill()
          this.ctx.closePath()
        }
      })
      this.ctx.lineWidth = 2
      // this.dataList.map((val, idx) => {
      //   let sx = this.radius + Math.sin(this.dotDeg * idx) * (this.er - 32)
      //   let sy = this.radius + Math.cos(this.dotDeg * idx) * (this.er - 32)
      //   let ex = this.radius + Math.sin(this.dotDeg * idx) * (this.er + val.y)
      //   let ey = this.radius + Math.cos(this.dotDeg * idx) * (this.er + val.y)
      //   let ex1 = this.radius + Math.sin(this.dotDeg * idx) * (this.er - val.y)
      //   let ey1 = this.radius + Math.cos(this.dotDeg * idx) * (this.er - val.y)
      //   this.ctx.beginPath()
      //   this.ctx.strokeStyle = this.color
      //   this.ctx.moveTo(sx, sy)
      //   this.ctx.lineTo(ex, ey)
      //   this.ctx.stroke()
      //   this.ctx.closePath()
      //   this.ctx.beginPath()
      //   this.ctx.strokeStyle = this.color2
      //   this.ctx.moveTo(sx, sy)
      //   this.ctx.lineTo(ex1, ey1)
      //   this.ctx.stroke()
      //   this.ctx.closePath()
      // })
    },
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style scoped lang="stylus">
.yy_wave_dot
  position absolute 
  display none
</style>