<template>
  <div class="cxks-header">
    <div class="headerBg"></div>
    <div class="logo"><img src="@assets/images/logo.svg" alt="晨兴可视"></div>
    <div class="menuList">
      <div class="item" v-for="(item, idx) in menu" :key="'menu'+idx" :class="{active: item.name === active}">
        <rotate-title :title="item.name === active ? item.label.toUpperCase() : item.label" @click="clickMenu(item)"></rotate-title>
      </div>
    </div>
    <lang class="headLang"></lang>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import rotateTitle from '@/components/base/rotate-title.vue'
import lang from '@/components/base/lang.vue'
export default {
  data () {
    return {
      menu: [
        {label: '', name: 'home'},
        {label: '', name: 'product'},
        {label: '', name: 'services'},
        {label: '', name: 'contact'},
      ],
      active: 'home',
      isClick: false,
    }
  },
  computed: {
    ...mapGetters(['currentIdx'])
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.menu.map(val => {
        val.label = this.$t(`menu.${val.name}`)
      })
    },
    clickMenu (item) {
      this.$emit('clickMenu', item)
      this.active = item.name
      this.isClick = true
      setTimeout(() => {
        this.isClick = false
      }, 1000);
    },
    changeIdx () {
      $('.item').blur()
      if (!this.isClick) {
        if (this.currentIdx === 0 || this.currentIdx === 1) {
          this.active = this.menu[0].name
        } else if (this.currentIdx === 2 || this.currentIdx === 3 || this.currentIdx === 4) {
          this.active = this.menu[1].name
        } else if (this.currentIdx === 5) {
          this.active = this.menu[2].name
        } else if (this.currentIdx === 6 || this.currentIdx === 7) {
          this.active = this.menu[3].name
        }
      }
    },
  },
  beforeDestroy () {
  },
  watch: {
    language () {
      this.getData()
    },
    currentIdx () {
      this.changeIdx()
    },
  },
  components: {rotateTitle, lang}
}
</script>
<style scoped lang="stylus">
.cxks-header
  position absolute
  top 0
  left 0
  width 100%
  height 90px
  display flex
  z-index 6
  .headerBg
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background: linear-gradient(180deg, rgba(0,0,17,1) 0%, rgba(0,0,17,0) 100%);
    z-index 0
  .logo, .menuList, .headLang
    position relative
    z-index 3
  .logo
    margin 22px 0 0 40px
  .menuList
    display flex
    flex 1
    margin 22px 0 0 5%
    .item
      margin 0 20px
      width 100px
      height 20px
      text-align center
      >>>.cx-font1
        color #EEEEEE
        font-size 16px
        line-height 20px
        letter-spacing 1px
      &:hover
        >>>.cx-rotate-title
          .cx-font1
            font-size 18px
            fontWeight1()
            color transparent
            background linear-gradient(180deg, #00FFC2 0%,#00FFC2 30%, #1278E3 80%, #1278E3 100%)
            -webkit-background-clip text
            background-clip text
    .active
      position relative
      &:before
        content ''
        position absolute
        top 20px
        left calc(50% - 4px)
        display block
        width 0
        height 0
        border 6px solid transparent
        border-bottom 9px solid #00FFC2
      >>>.cx-font1
        fontWeight1()
        font-size 18px
        color transparent
        background linear-gradient(180deg, #00FFC2 0%,#00FFC2 30%, #1278E3 80%, #1278E3 100%)
        -webkit-background-clip text
        background-clip text
  .headLang
    margin 22px 40px 0 0
    z-index 10
@media screen and (max-width: 900px)
  .cxks-header
    .menuList
      margin 22px 0 0 2%
      .item
        margin 0 12px
@media screen and (max-width: 800px)
  .cxks-header
    height 70px
    .logo
      margin 0.5rem 0 0 1rem
    .menuList
      margin 0.5rem 0 0 0
      .item
        margin 0 0.1rem
        width 3rem
        >>>.cx-font1
          font-size 0.45rem
    .headLang
      margin 0.5rem 1rem 0 0
@media screen and (max-width: 600px)
  .cxks-header
    .logo
      flex 1
    .menuList
      position absolute
      left 0
      top 56px
      .item
        margin 0
        width 4.35rem
        >>>.cx-font1
          font-size 0.7rem
        >>>.cx-rotate-title
          &:hover
            .cx-font1
              font-size 0.7rem
</style>