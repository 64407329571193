import { render, staticRenderFns } from "./text6.vue?vue&type=template&id=603d4855"
import script from "./text6.vue?vue&type=script&lang=js"
export * from "./text6.vue?vue&type=script&lang=js"
import style0 from "./text6.vue?vue&type=style&index=0&id=603d4855&prod&lang=stylus&scope=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports