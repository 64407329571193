
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { Flow } from 'three/examples/jsm/modifiers/CurveModifier.js';
import {data0, data1, data2} from './data.js'
import TWEEN from '@tweenjs/tween.js'
import * as THREE from 'three'

export default class SJ {
  constructor (boxId, config) {  
    this.font = null
    this.boxId = null 
    this.width = 0
    this.height = 0
    this.scene = null
    this.camera = null
    this.renderer = null
    this.container = null
    this.publicPath = process.env.BASE_URL
    this.redPoint = {x: 0, y: 8, z: 0}
    this.pointSize = 0
    this.config = null
    this.mouseX = 2
    this.mouseY = 2
    this.isMoveY = true
    this.list = []
    let defaultConfig = {
      that: null,
      background: 0x020202, // 大场景背景，null则无背景色
      per_fov: 20,
      showFog: false,
      fogColor: 0x000000,
      fogFar: 10, 
      fogNear: 1,
      cameraPositioin: [15, 0, 15],
      targetPosition: [0,0,0],
      minPolarAngle: -0.1*Math.PI,
      maxPolarAngle: 0.1*Math.PI,
      minZoom: 0.01,
      maxZoom: 1,
      maxDistance: 50,
      minDistance: 26,
      lightList: [],
      showLightBox: false,
      renderMethod: null,
    }
    this.config = Object.assign(defaultConfig, config)
    this.starList = []
    this.init()
    this.initRenderer()
    this.initLight()
    this.initEvent()
    this.render() 
    this.initPage1()
    this.initPage2()
    this.initPage3()
    this.initPage4()
  }
  init (boxId) {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.clock = new THREE.Clock() 
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(this.config.per_fov, this.width/this.height, 1, 300)
    this.camera.position.set(...this.config.cameraPositioin)
    
    this.canvasMat1 = new THREE.MeshBasicMaterial({ transparent: true, opacity: 1, color: 0xffffff})
    this.canvasMat2 = new THREE.MeshBasicMaterial({ transparent: true, opacity: 1, color: 0xffffff})
  }
  initRenderer () {   
    var canvas = document.getElementById('modelBox')
    canvas.width = this.width
    canvas.height = this.height
    var context = canvas.getContext( 'webgl2', { alpha: true, antialias: true } );
    this.renderer = new THREE.WebGLRenderer( { canvas: canvas, context: context } );
    this.container = canvas
    this.renderer.setSize(this.width, this.height) // 渲染的画布宽高
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.autoClear = false    

    this.controls = new OrbitControls(this.camera, this.container);
    this.controls.enabled = false  
    // this.controls.enablePan = false
    // this.controls.enableZoom = false
    // this.controls.enableDamping = true
    // this.controls.minPolarAngle = 0.49*Math.PI
    // this.controls.maxPolarAngle = 0.51*Math.PI
    // this.controls.minAzimuthAngle = 0.49*Math.PI
    // this.controls.maxAzimuthAngle = 0.51*Math.PI
  }
  initLight () {    
    this.lightGroup = new THREE.Group()
    this.lightGroup.name = 'lightGroup'
    this.scene.add(this.lightGroup)   
    let lightList = []
    this.config.lightList.map(val => {
      let light
      if (val.type === 'AmbientLight') {        
        light = new THREE.AmbientLight(val.color, val.intensity);
      } else if (val.type === 'PointLight') {        
        light = new THREE.PointLight(val.color, val.intensity, val.distance)
        light.position.set(...val.position)
        light.shadow.bias = 0.05
        light.shadow.normalBias = 0.05
        if (val.castShadow) {
          light.castShadow = val.castShadow
          light.shadow.mapSize.width = 2048;
          light.shadow.mapSize.height = 2048;
        }        
      } else if (val.type === 'HemisphereLight') {
        light = new THREE.HemisphereLight(val.color1, val.color2, val.intensity)
      } else if (val.type === 'DirectionalLight') {
        light = new THREE.DirectionalLight(val.color, val.intensity)
        light.position.set(...val.position)
      } else if (val.type === 'RectAreaLight') {
        light = new THREE.RectAreaLight(val.color, val.intensity, val.width, val.height)
        light.position.set(...val.position)
        light.lookAt(...val.lookAt)
      }
      if (val.cloneList && val.cloneList.length > 0) {
        val.cloneList.map(item => {
          let clone = light.clone()
          clone.position.set(...item.p)
          this.lightGroup.add(clone)
        })        
      }
      light.name = val.name
      lightList.push(light)
      this.lightGroup.add(light)
    })
    if (this.config.showLightBox) {
      var box = new THREE.BoxGeometry(1, 1, 1)
      var material = new THREE.MeshBasicMaterial({color: 0xff0000})
      var mesh = new THREE.Mesh(box, material)
      this.config.lightList.map((val, idx) => {
        if (val.type === 'PointLight') {  
          var mesh1 = mesh.clone()
          mesh1.position.set(...val.position)
          this.lightGroup.add(mesh1)
        } else if (val.type === 'RectAreaLight') {          
				  this.lightGroup.add( new RectAreaLightHelper( lightList[idx] ) );
        }
      })
    } 
  }
  render () {
    TWEEN.update()
    this.renderUpdate()
    this.controls.update()
    this.renderer.render(this.scene, this.camera)
    this.animationID = requestAnimationFrame(() => {this.render()})
  }
  renderUpdate () {
    if (this.earthGroup) {
      this.earthGroup.rotation.y += 0.003
      let mesh = this.earthGroup.getObjectByName('dengMesh')
      if (mesh) {
        mesh.material.alphaMap.offset.x += 0.00048
      }
    }
    if (this.starGroup1) {
      this.starGroup1.rotation.y += 0.001
    }
    if (this.dotGroup1 && this.dotList1.length > 0) {
      this.dotList1.map(val => {
        val.mesh.rotation.x += val.rotation
        val.mesh.rotation.y += val.rotation
      })
    }
    if (this.dotGroup2 && this.dotList2.length > 0) {
      this.dotList2.map(val => {
        val.mesh.rotation.x += val.rotation
        val.mesh.rotation.y += val.rotation
      })
    }
    if (this.dotGroup3 && this.dotList3.length > 0) {
      this.dotList3.map(val => {
        val.mesh.rotation.x += val.rotation
        val.mesh.rotation.y += val.rotation
      })
    }
    // this.camera.position.x += ( this.mouseX * 0.01 - this.camera.position.x * 8 ) * .1;
    if (this.isMoveY) {
      this.camera.position.y += ( this.mouseY * 0.01 - this.camera.position.y * 8 ) * .1;
      this.camera.lookAt(this.scene.position)
    }
  }
  destroyWindowResize () {
    this.scene.clear()
    this.renderer.dispose()
    this.renderer.forceContextLoss()
    this.renderer.content = null
    cancelAnimationFrame(this.animationID)
    let gl = this.renderer.domElement.getContext('webgl')
    gl && gl.getExtension('WEBGL_lose_context').loseContext()
    $(window).unbind('resize')
    $(window).unbind('mousemove')
  }
  initEvent () {
    if (this.isMoveY) {
      $(window).bind('resize', () => {this.onWindowResize()})
      $(window).bind('mousemove', (e) => {this.onWindowMousemove(e)})
    }
  }
  onWindowResize () {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.renderer.setSize(this.width, this.height)
    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()
  }
  onWindowMousemove (e) {
    if (this.isMoveY && this.width >= 600) {
      this.mouseX = ( event.clientX - this.width / 2 );
      this.mouseY = ( event.clientY - this.height / 2 );
    }
  }
  getPath (list) {
    var arr = []
    list.map(val => {
      arr.push(new THREE.Vector3( val[0], val[1], val[2] ))
    })
    var path = new THREE.CatmullRomCurve3( arr, false, 'catmullrom', 0.01 )
    return path
  }
  getxy (r, total, i) {
    var x = Math.sin(2 / total * i * Math.PI) * r
    var y = Math.cos(2 / total * i * Math.PI) * r
    return {x, y}
  }
  getPointPosition (p, scale) {
    let ex = p[0] * scale
    let ey = p[1] * scale
    let ez = p[2] * scale
    return {ex, ey, ez}
  }
  removeGroup (arr) { // 清除场景内的模型组
    if (this.page1Tween) {
      this.page1Tween.map(val => {
        val.stop()
      })
      this.page1Tween = []
    }
    if (this.page2Tween) {
      this.page2Tween.map(val => {
        val.stop()
      })
      this.page2Tween = []
    }
    if (this.page3Tween) {
      this.page3Tween.map(val => {
        val.stop()
      })
      this.page3Tween = []
      if (this.textInterval) {
        clearInterval(this.textInterval)
      }
      if (this.setTimeoutIdx) {
        clearTimeout(this.setTimeoutIdx)
      }
      if (this.dot6) {        
        this.dot6.changeName(-1)
      }
      if (this.width < 600 && this.cxPhone6) {
        this.cxPhone6.changeName(-1)
      }
    }
    if (this.page4Tween) {
      this.page4Tween.map(val => {
        val.stop()
      })
      if (this.page4Interval) {
        clearInterval(this.page4Interval)
      }
      this.page4Tween = []
    }
    this.scene.environment = null
    let visList = ['earthGroup', 'page3Group', 'page4Group']
    arr.map(val => {
      let idx = visList.findIndex(item => {return item === val})
      if (idx >= 0) {
        this[val].visible = false
      } else {
        this.scene.remove(this[val])
        this[val] = null
      }
    })
  }
  initPage1 () {  // page1    
    this.earthGroup = new THREE.Group()
    this.earthGroup.name = 'earthGroup'
    this.scene.add(this.earthGroup)
    let earthMat = new THREE.MeshStandardMaterial({color: 0x101033, roughness: 0.5, metalness: 0.8 })
    let earthMat1 = new THREE.MeshPhysicalMaterial({color: 0x1166FF, metalness: 0.1, roughness: 0.6, ior: 1.1, envMapIntensity: 1, transmission: 0.4, specularIntensity: 1, specularColor: 0xffffff, opacity: 0.95, side: THREE.FrontSide, transparent: true})

    let file = `${this.publicPath}model/地球模型.glb`
    let loader = new GLTFLoader();
    let dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( `./draco/` );
    dracoLoader.setDecoderConfig( { type: 'js' } );
    dracoLoader.preload();
    loader.setDRACOLoader(dracoLoader)
    
    loader.load(`${file}`, obj => { 
      obj.scene.traverse(e => {
        if (e.isMesh) {
          if (e.name === 'world002') {
            e.material = earthMat
          } else if (e.name === 'Sphere') {
            e.material = earthMat1
            e.scale.set(1.03,1.03,1.03)
          }
        }
      })  
      obj.scene.scale.set(2.2,2.2,2.2)
      this.earthGroup.add(obj.scene)  
    })

    let geo = new THREE.SphereGeometry(2.2, 32, 32)
    let mat = new THREE.MeshBasicMaterial({ transparent: true, map: new THREE.TextureLoader().load(`${this.publicPath}image/font.png`), side: THREE.DoubleSide})
    let fontMesh = new THREE.Mesh(geo, mat)  
    fontMesh.renderOrder = 3  
    this.earthGroup.add(fontMesh)

    let dengGeo = new THREE.SphereGeometry(1.65, 32, 32)
    let dengMap = new THREE.TextureLoader().load(`${this.publicPath}image/deng1.png`)
    dengMap.wrapS = dengMap.wrapT = THREE.RepeatWrapping
    dengMap.offset.set(0.26, 0.1)
    let opacityMap = new THREE.TextureLoader().load(`${this.publicPath}image/dengO.png`)
    opacityMap.wrapS = opacityMap.wrapT = THREE.RepeatWrapping
    opacityMap.offset.set(0.2, 0)
    let dengMat = new THREE.MeshPhongMaterial({ transparent: true, color: 0xff6622, emissive: 0xff5500 , map: dengMap, opacity: 2, alphaMap: opacityMap, side: THREE.DoubleSide})
    let dengMesh = new THREE.Mesh(dengGeo, dengMat)  
    dengMesh.name = 'dengMesh'
    dengMesh.renderOrder = 2
    this.earthGroup.add(dengMesh)
    this.page1Tween = []
  }
  initPage1_1 () {
    this.starGroup = new THREE.Group()
    this.starGroup.name = 'starGroup'
    this.scene.add(this.starGroup)

    let dotGeo = new THREE.OctahedronGeometry(0.1, 0)
    let mat = new THREE.MeshStandardMaterial({color: 0x55BBFF, metalness: 0.8, roughness: 0.5, transparent: true, opacity: 0.85, side: THREE.DoubleSide})
    let dotMesh = new THREE.Mesh(dotGeo, mat)
    this.starList = []
    let maxH = 26
    for (let h = -1 * maxH; h <= maxH; h++) {
      let height = h * 0.1
      let r = 6 + Math.random()*4
      let total = Math.ceil(Math.random() * 30)
      let rotationY = Math.random()
      let g = new THREE.Group()
      g.rotation.y = rotationY
      g.scale.set(0.05,0.05,0.05)
      let objGroup = {group: g}
      for(let i = 0; i < total; i++) {
        let scale = Math.max(0.2, Math.random() * 0.5)
        let dot = dotMesh.clone()
        dot.rotation.y = 0.3 * Math.PI
        let {x, y} = this.getxy(r, total, i)
        dot.position.set(x, height, y)
        dot.scale.set(scale, scale, scale)
        g.add(dot)
      }
      this.starGroup.add(g)
      this.starList.push(objGroup)
    }
  }
  movePage1_0 (type) { // 回最顶部
    let arr = ['starGroup1', 'dotGroup1', 'dotGroup2', 'dotGroup3', 'page3Group', 'page4Group']
    this.removeGroup(arr)
    this.camera.position.set(...[15,0,15])
    this.controls.target = new THREE.Vector3(...[0,0,0])
    this.isMoveY = true
    this.earthGroup.visible = true
    let scfg = { scale: 0, eRotate: 0, gs: 1, rList: []}
    let ecfg = { scale: 1, eRotate: 4, gs: 0.05, rList: []}   
    this.starList.map(val => {
      let x = Math.random()*0.5
      scfg.rList.push( x )
      ecfg.rList.push( x + 1 )
    })
    let tween1 = new TWEEN.Tween(scfg).to(ecfg, 500).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
      this.earthGroup.scale.set(scfg.scale, scfg.scale, scfg.scale)
      this.earthGroup.rotation.y = scfg.eRotate * Math.PI
      this.starList.map((val, idx) => {
        val.group.scale.set(scfg.gs, scfg.gs, scfg.gs)
        val.group.rotation.y = scfg.rList[idx]
      })
    }).onComplete(() => {
      let arr = ['starGroup']
      this.removeGroup(arr)
    }).start()
    this.page1Tween.push(tween1) 
  }
  movePage1_1 (type) { // 进入满天星状态
    let arr = ['starGroup']
    this.removeGroup(arr)
    this.isMoveY = true
    this.initPage1_1()
    if (type == 'down') {  // 地球 --> 满天星
      let arr = ['starGroup1', 'dotGroup1', 'dotGroup2', 'dotGroup3', 'page3Group', 'page4Group']
      this.removeGroup(arr)
      let scfg = {eScale: 1, eRotate: 0, gScale: 0.05, rList: []}
      let ecfg = {eScale: 0.6, eRotate: 1, gScale: 0.2, rList: []}    
      this.starList.map(val => {
        let x = Math.random()*0.5
        scfg.rList.push( x )
        ecfg.rList.push( x + 1 )
      })
      let tween1 = new TWEEN.Tween(scfg).to(ecfg, 800).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        this.earthGroup.scale.set(scfg.eScale, scfg.eScale, scfg.eScale)
        this.earthGroup.rotation.y = scfg.eRotate * Math.PI
        this.starList.map((val, idx) => {
          if (val.group) {
            val.group.scale.set(scfg.gScale, scfg.gScale, scfg.gScale)
            val.group.rotation.y = scfg.rList[idx]
          }
        })
      }).onComplete(() => {
        this.movePage1_2()
      }).start()
      this.page1Tween.push(tween1) 
    } else if (type == 'up') { // 网站图标 --> 满天星
      let arr = ['earthGroup', 'dotGroup1', 'dotGroup2', 'dotGroup3', 'page3Group', 'page4Group']
      this.removeGroup(arr)
      let scfg = {camera: [5,2,0],target: [1.78,0.67,1.78], len: 0, gScale: 0.05,rList: []}
      let ecfg = {camera: [15,0,15],target: [0,0,0], len: this.starGroup1.children.length,gScale: 1,rList: []}   
      this.starList.map(val => {
        let x = 1 + Math.random()*0.5
        scfg.rList.push( x )
        ecfg.rList.push( x + 1 )
      }) 
      let removeIdx = 0
      let tween2 = new TWEEN.Tween(scfg).to(ecfg, 800).easing(TWEEN.Easing.Quartic.Out).onUpdate(() => {
        this.camera.position.set(...scfg.camera)
        this.controls.target = new THREE.Vector3(...scfg.target)
        if (this.dotGroup1 && this.dotGroup1.children) {
          this.starGroup1.children.map((val, idx) => {
            if (idx <= Math.ceil(scfg.len) - removeIdx) {
              this.starGroup1.remove(val)
            }
          })
          removeIdx = Math.ceil(scfg.len)
        }
        this.starList.map((val, idx) => {
          val.group.scale.set(scfg.gScale, scfg.gScale, scfg.gScale)
          val.group.rotation.y = scfg.rList[idx]
        })
      }).onComplete(() => {
        this.scene.remove(this.starGroup1)
        this.resetPage2Dot(this.dotList1)
        this.movePage1_3()
      }).start()
      this.page1Tween.push(tween2) 
    }
  }
  movePage1_2 () {  
    let scfg = {eScale: 0.6, eRotate: 1, gScale: 0.2, rList: []}
    let ecfg = {eScale: 0, eRotate: 1.5, gScale: 1, rList: []}    
    this.starList.map(val => {
      let x = 1 + Math.random()*0.5
      scfg.rList.push( x )
      ecfg.rList.push( x + 2 )
    })
    let tween1 = new TWEEN.Tween(scfg).to(ecfg, 300).easing(TWEEN.Easing.Quartic.Out).onUpdate(() => {
      this.earthGroup.scale.set(scfg.eScale, scfg.eScale, scfg.eScale)
      this.earthGroup.rotation.y = scfg.eRotate * Math.PI
      this.starList.map((val, idx) => {
        if (val.group) {
          val.group.scale.set(scfg.gScale, scfg.gScale, scfg.gScale)
          val.group.rotation.y = scfg.rList[idx]
        }
      })
    }).onComplete(() => {
      this.movePage1_3()
    }).start()
    this.page1Tween.push(tween1) 
  }
  movePage1_3 () {
    let scfg = {rotate: 0, }
    let ecfg = {rotate: 2, }  
    let tween1 = new TWEEN.Tween(scfg).to(ecfg, 220000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
      if (this.starGroup) this.starGroup.rotation.y = scfg.rotate*Math.PI
    }).repeat(Infinity).onComplete(() => {
    }).start()
    this.page1Tween.push(tween1) 
  }
  initPage2 () {  // page2
    let file = `${this.publicPath}model/system.glb`
    let loader = new GLTFLoader();
    let dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( `./draco/` );
    dracoLoader.setDecoderConfig( { type: 'js' } );
    dracoLoader.preload();
    loader.setDRACOLoader(dracoLoader) 

    let dotMat = new THREE.ShaderMaterial({
      transparent: true,
      vertexShader: `
      varying float h;
      varying vec3 vNormal;
      uniform float maxH;
      void main() {        
        // 世界坐标系        
        vec4 WorldPosition = modelMatrix * vec4 (position,1.0);
        h = WorldPosition.y / maxH + 0.2;
        vNormal = normal;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);      
      }
    `,
      fragmentShader: `
      varying float h;
      varying vec3 vNormal;
      uniform vec3 u_lightDirection;
      uniform vec3 u_lightDirection1;
      uniform vec3 s_color;
      uniform vec3 e_color;
      void main() {
          vec3 faceNormal = normalize(vNormal);
          float n = max(dot(u_lightDirection, faceNormal), dot(u_lightDirection1, faceNormal));
          float nDotL = max(n, 0.3);
          float r = s_color.r + (e_color.r - s_color.r) * h;
          float g = s_color.g + (e_color.g - s_color.g) * h;
          float b = s_color.b + (e_color.b - s_color.b) * h;
          float o = h <= 0.9 ? 0.9 : h;
          gl_FragColor = vec4(r * nDotL, g * nDotL, b * nDotL, o) ;
      }`,
      uniforms: {
        e_color: {
          value: new THREE.Color('rgb(200, 255, 255)')
        },
        s_color: {
          value: new THREE.Color('rgb(190, 230, 255)')
        },
        maxH: {
          value: 1.5
        },
        u_lightDirection : {value : new THREE.Vector3(5,2,2).normalize()},
        u_lightDirection1 : {value : new THREE.Vector3(-5,4,2).normalize()},
      },
      transparent: true,
      side: THREE.DoubleSide,
    })
    let dotGeo = new THREE.OctahedronGeometry(0.1, 0)
    let dotMesh = new THREE.Mesh(dotGeo, dotMat) 
    this.dotList1 = []
    loader.load(`${file}`, obj => {
      obj.scene.traverse(e => {
        if (e.isPoints) {
          this.getPage2ModelObj(dotMesh, e, this.dotList1)
        }
      })  
    }) 
    let file1 = `${this.publicPath}model/pie.glb`
    this.dotList2 = []
    loader.load(`${file1}`, obj => {
      obj.scene.traverse(e => {
        if (e.isPoints) {
          this.getPage2ModelObj(dotMesh, e, this.dotList2)
        }
      })  
    }) 
    let file2 = `${this.publicPath}model/app.glb`
    this.dotList3 = []
    loader.load(`${file2}`, obj => {
      obj.scene.traverse(e => {
        if (e.isPoints) {
          this.getPage2ModelObj(dotMesh, e, this.dotList3)
        }
      })  
    }) 
    this.page2Tween = []
  }
  getPage2ModelObj (dotMesh, e, array) {
    let total = e.geometry.attributes.position.count
    let arr = e.geometry.attributes.position.array
    for (let i = 0; i < total; i++) {
      let ep = [ arr[i * 3],  arr[i*3+2],arr[i*3+1],]
      let {ex, ey, ez} = this.getPointPosition(ep, 15)
      let r = Math.random() * 20
      let sDeg = Math.random()*2*Math.PI
      let x = Math.cos(sDeg) * r
      let y = ey
      let z = Math.sin(sDeg) * r
      let scale = (1.2 + ez) * 0.2
      let rotation = Math.max(Math.random() * 0.05, 0.008)
      let mesh = dotMesh.clone()
      mesh.position.set(x,y,z)
      mesh.scale.set(scale, scale, scale)
      mesh.rotation.x =  Math.random() * 5
      array.push({mesh: mesh, sp: [x,y,z], scale, rotation, r, sDeg, ep: [ex, ey, ez]})
    }
  }
  resetPage2Dot (arr) {
    arr.map(val => {
      val.r = Math.random() * 20
      val.mesh.position.set(...val.sp)
    })
  }
  initPage2_1 () {
    this.starList = []  
    let starTotal = 300
    let dotGeo = new THREE.OctahedronGeometry(0.1, 0)
    let mat = new THREE.MeshStandardMaterial({color: 0x55AAFF, metalness: 0.8, roughness: 0.5, transparent: true, opacity: 0.8, side: THREE.DoubleSide}) 
    let starDot = new THREE.Mesh(dotGeo, mat)
    for (let i = 0; i < starTotal; i++) {
      let r = Math.max(Math.random() * 10, 2)
      let sDeg = Math.random()*2*Math.PI
      let x = Math.cos(sDeg) * r
      let y = Math.random()*3 * (Math.random() > 0.5 ? 1 : -1)
      let z = Math.sin(sDeg) * r
      let mesh = starDot.clone()
      mesh.position.set(x, y, z)
      let scale = Math.max(Math.random() * 0.4, 0.08)
      mesh.scale.set(scale, scale, scale)
      this.starList.push({mesh: mesh, sp: [x,y,z], rotation: Math.max(Math.random() * 0.05, 0.008)})
    }
  }
  movePage2_1 (type) { // 切换到网站图标
    let arr = ['earthGroup', 'dotGroup1', 'dotGroup3', 'page3Group', 'page4Group']      
    this.removeGroup(arr)
    this.isMoveY = true
    this.dotGroup1 = new THREE.Group()
    this.dotGroup1.name = 'dotGroup1'
    this.scene.add(this.dotGroup1)
    if (type == 'down') { // 满天星 --> 网站图标
      let arr = ['dotGroup2', 'starGroup1',]
      this.removeGroup(arr)
      this.starGroup1 = new THREE.Group()
      this.starGroup1.name = 'starGroup1'
      this.scene.add(this.starGroup1)
      this.initPage2_1()
      let scfg = {len: 0, starLen: 0, dotLen: 0, camera:[15,0,15]} 
      let eCfg = {len: 30, starLen: this.starList.length, dotLen: this.dotList1.length, camera: [5,2,0]} 
      let len = 0
      let starIdx = -1
      let dotIdx = -1
      let tween1 = new TWEEN.Tween(scfg).to(eCfg, 1000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        this.camera.position.set(...scfg.camera)
        if (this.starGroup && this.starGroup.children) {
          this.starGroup.children.map((val, idx) => {
            if (val.children && val.children.length > 0 && len < Math.ceil(scfg.len)) {
              val.remove( val.children[Math.floor(Math.random() * val.children.length)])
            } else if (val.children.length == 0) {
              this.starGroup.remove(val)
            }
          })
        }
        this.starList.map((val, idx) => {
          if (idx > starIdx && idx <= scfg.starLen) {
            this.starGroup1.add(val.mesh)
            starIdx = idx
          }
        })
        this.dotList1.map((val, idx) => {
          if (idx > dotIdx && idx <= scfg.dotLen && this.dotGroup1) {
            this.dotGroup1.add(val.mesh)
            dotIdx = idx
          }
        })
      }).onComplete(() => {
        this.movePage2_2()
      }).start()
      this.page2Tween.push(tween1) 
    } else if (type == 'up') { // 可视化图标 --> 网站图标
      let arr = ['starGroup']
      this.removeGroup(arr)
      this.resetPage2Dot(this.dotList1)
      let scfg = {camera: [-8.93,-1.25,14.31],target: [2.85,0.43,1.52], len: 0, dotLen: 0}
      let ecfg = {camera:  [5,2,0],target: [0,0,0], len: this.dotGroup2.children.length,gScale: 1,dotLen: this.dotList1.length}   
      let removeIdx = 0
      let dotIdx = -1
      let tween2 = new TWEEN.Tween(scfg).to(ecfg, 1000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        this.camera.position.set(...scfg.camera)
        this.controls.target = new THREE.Vector3(...scfg.target)
        if (this.dotGroup2 && this.dotGroup2.children) {
          this.dotGroup2.children.map((val, idx) => {
            if (idx <= Math.ceil(scfg.len) - removeIdx) {
              this.dotGroup2.remove(val)
            }
          })
          removeIdx = Math.ceil(scfg.len)
        }
        this.dotList1.map((val, idx) => {
          if (idx > dotIdx && idx <= scfg.dotLen && this.dotGroup1) {
            this.dotGroup1.add(val.mesh)
            dotIdx = idx
          }
        })
      }).onComplete(() => {
        this.movePage2_2()
      }).start()
      this.page2Tween.push(tween2) 
    }
  }
  movePage2_2 () { 
    this.removeGroup(['starGroup'])
    this.starGroup = null
    let scfg = { y: 0, x: 0, maxH: 1.5, line: -3, target: [0,0,0], camera: [5,2,0]}
    let ecfg = { y: 0.65, x: 0.2, maxH: 0.6, line: 3, target: this.width < 600 ? [-0.11,2.53,1.59] : [2.88,0.49,2.21], camera: this.width < 600 ? [10.98,-0.44,-25.66] : [9.77,-0.34,-15.64]}
    let tween1 = new TWEEN.Tween(scfg).to(ecfg, 2000).easing(TWEEN.Easing.Quadratic.Out).onUpdate(() => {  
      this.camera.position.set(...scfg.camera)
      this.controls.target = new THREE.Vector3(...scfg.target)
      this.dotList1[0].mesh.material.uniforms.maxH.value = scfg.maxH
      if (this.dotGroup1) {
        this.dotGroup1.rotation.y = scfg.y * Math.PI
        this.dotGroup1.rotation.x = scfg.x * Math.PI
      }      
      this.dotList1.map((val, idx) => {
        val.sDeg -= 0.03
        val.r -= 0.001
        if (val.ep[1] < scfg.line) {
          val.r -= 0.085 * val.r
        }
        val.r = val.r < 0 ? 0 : val.r
        let x = Math.cos(val.sDeg) * val.r + val.ep[0]
        let z = Math.sin(val.sDeg) * val.r + val.ep[2]
        val.mesh.position.set(x, val.ep[1], z)
      })
    }).onComplete(() => {
      this.dotList1.map(val => {
        if (val.ep) {
          val.mesh.position.set(...val.ep)
        }
      })
    }).start()
    this.page2Tween.push(tween1) 
  }
  movePage2_4 (type) { // 切换到可视化图标
    let arr = ['earthGroup', 'starGroup', 'dotGroup2', 'page3Group', 'page4Group']      
    this.removeGroup(arr)
    this.isMoveY = true
    this.dotGroup2 = new THREE.Group()
    this.dotGroup2.name = 'dotGroup2'
    this.scene.add(this.dotGroup2)
    this.resetPage2Dot(this.dotList2)
    if (type == 'down') { // 网站图标 --> 可视化图标
      let arr = ['dotGroup3',]
      this.removeGroup(arr)
      let scfg = { len: 0,dotLen: 0, maxH: 0.6, line: -3, target: [1.78,0.67,1.78], camera: [7.39,2.54,-12.76]}
      let ecfg = { len: this.dotGroup1.children.length, dotLen: this.dotList2.length, maxH: 1.5, line: 3, target: [0,0,0], camera: [0,2,0]}
      let removeIdx = 0
      let dotIdx = -1
      let tween1 = new TWEEN.Tween(scfg).to(ecfg, 1000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        this.dotList2[0].mesh.material.uniforms.maxH.value = scfg.maxH
        this.camera.position.set(...scfg.camera)
        this.controls.target = new THREE.Vector3(...scfg.target)
        if (this.dotGroup1 && this.dotGroup1.children) {
          this.dotGroup1.children.map((val, idx) => {
            if (idx <= Math.ceil(scfg.len) - removeIdx) {
              this.dotGroup1.remove(val)
            }
          })
          removeIdx = Math.ceil(scfg.len)
        }
        this.dotList2.map((val, idx) => {
          if (idx > dotIdx && idx <= scfg.dotLen && this.dotGroup2) {
            this.dotGroup2.add(val.mesh)
            dotIdx = idx
          }
        })
      }).onComplete(() => {
        this.movePage2_5()
      }).start()
      this.page2Tween.push(tween1) 
    } else if (type == 'up') { // 系统图标 --> 可视化图标
      let arr = ['dotGroup1']
      this.removeGroup(arr)
      let scfg = {camera: [7.39,2.54,-12.76],target: [1.78,0.67,1.78], len: 0, dotLen: 0}
      let ecfg = {camera: [5,2,0],target: [0,0,0], len: this.dotGroup3.children.length,gScale: 1,dotLen: this.dotList2.length}   
      let removeIdx = 0
      let dotIdx = -1
      let tween2 = new TWEEN.Tween(scfg).to(ecfg, 1000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        this.camera.position.set(...scfg.camera)
        this.controls.target = new THREE.Vector3(...scfg.target)
        if (this.dotGroup3 && this.dotGroup3.children) {
          this.dotGroup3.children.map((val, idx) => {
            if (idx <= Math.ceil(scfg.len) - removeIdx) {
              this.dotGroup3.remove(val)
            }
          })
          removeIdx = Math.ceil(scfg.len)
        }
        this.dotList2.map((val, idx) => {
          if (idx > dotIdx && idx <= scfg.dotLen && this.dotGroup2) {
            this.dotGroup2.add(val.mesh)
            dotIdx = idx
          }
        })
      }).onComplete(() => {
        this.movePage2_5()
      }).start()
      this.page2Tween.push(tween2) 
    }
  }
  movePage2_5 () {
    this.scene.remove(this.dotGroup1)
    let scfg = { maxH: 1.5, line: -3, target: [0,0,0], camera: [0,2,0]}
    let ecfg = { maxH: 0.6, line: 3, target: this.width < 600 ? [1.08,2.21,-0.00] : [3.40,0.43,2.03], camera: this.width < 600 ? [-20.72,-0.44,23.66] : [-9.00,-0.34,15.49]}
    let tween1 = new TWEEN.Tween(scfg).to(ecfg, 2000).easing(TWEEN.Easing.Quadratic.Out).onUpdate(() => { 
      this.dotList2[0].mesh.material.uniforms.maxH.value = scfg.maxH
      this.camera.position.set(...scfg.camera)
      this.controls.target = new THREE.Vector3(...scfg.target)
      this.dotList2.map((val, idx) => {
        val.sDeg -= 0.03
        val.r -= 0.001
        if (val.ep[1] < scfg.line) {
          val.r -= 0.085 * val.r
        }
        val.r = val.r < 0 ? 0 : val.r
        let x = Math.cos(val.sDeg) * val.r + val.ep[0]
        let z = Math.sin(val.sDeg) * val.r + val.ep[2]
        val.mesh.position.set(x, val.ep[1], z)
      })
    }).onComplete(() => {
      this.dotList2.map(val => {
        if (val.ep) {
          val.mesh.position.set(...val.ep)
        }
      })
    }).start() 
    this.page2Tween.push(tween1) 
  }
  movePage2_6 (type) { // 切换到系统图标
    let arr = ['earthGroup', 'starGroup', 'dotGroup1', 'page3Group', 'page4Group']      
    this.removeGroup(arr)
    this.isMoveY = true
    this.dotGroup3 = new THREE.Group()
    this.dotGroup3.name = 'dotGroup3'
    this.scene.add(this.dotGroup3)
    this.resetPage2Dot(this.dotList3)
    if (type == 'down') { // 可视化图标 --> 系统图标
      let dotsLen = this.dotGroup2 && this.dotGroup2.children ? this.dotGroup2.children.length : 0
      let dot3Len = this.dotList3.length || 0
      let scfg = { len: 0,dotLen: 0, maxH: 0.6, target: [2.85,0.43,1.52], camera: [-8.93,-1.25,14.31]}
      let ecfg = { len: dotsLen, dotLen: dot3Len, maxH: 1.5, target: [0,0,0], camera: [5,2,0]}
      let removeIdx = 0
      let dotIdx = -1
      let tween1 = new TWEEN.Tween(scfg).to(ecfg, 1000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        this.dotList3[0].mesh.material.uniforms.maxH.value = scfg.maxH
        this.camera.position.set(...scfg.camera)
        this.controls.target = new THREE.Vector3(...scfg.target)
        if (this.dotGroup2 && this.dotGroup2.children) {
          this.dotGroup2.children.map((val, idx) => {
            if (idx <= Math.ceil(scfg.len) - removeIdx) {
              this.dotGroup2.remove(val)
            }
          })
          removeIdx = Math.ceil(scfg.len)
        }
        this.dotList3.map((val, idx) => {
          if (idx > dotIdx && idx <= scfg.dotLen && this.dotGroup3) {
            this.dotGroup3.add(val.mesh)
            dotIdx = idx
          }
        })
      }).onComplete(() => {
        this.movePage2_7()
      }).start()
      this.page2Tween.push(tween1) 
    } else if (type == 'up') { // 斜面地球 --> 系统图标
      this.resetLightPage1_2()
      let arr = ['dotGroup2']
      this.removeGroup(arr)
      this.starGroup1 = new THREE.Group()
      this.starGroup1.name = 'starGroup1'
      this.scene.add(this.starGroup1)
      this.initPage2_1()
      this.dotList3.map(val => {
        val.mesh.scale.set(val.scale, val.scale, val.scale)
      })
      let scfg = {camera: [12,0,12],target: [0,0,0],  dotLen: 0, starLen: 0}
      let ecfg = {camera: [5,2,0],target: [0,0,0], dotLen: this.dotList3.length, starLen: this.starList.length,}  
      let dotIdx = -1
      let starIdx = -1
      let tween2 = new TWEEN.Tween(scfg).to(ecfg, 1000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        this.camera.position.set(...scfg.camera)
        this.controls.target = new THREE.Vector3(...scfg.target)
        this.dotList3.map((val, idx) => {
          if (idx > dotIdx && idx <= scfg.dotLen && this.dotGroup3) {
            this.dotGroup3.add(val.mesh)
            dotIdx = idx
          }
        })
        this.starList.map((val, idx) => {
          if (idx > starIdx && idx <= scfg.starLen) {
            this.starGroup1.add(val.mesh)
            starIdx = idx
          }
        })
      }).onComplete(() => {
        this.movePage2_7()
      }).start()
      this.page2Tween.push(tween2) 
    }
  }
  movePage2_7 () {
    this.scene.remove(this.dotGroup2)
    let scfg = { maxH: 1.5, line: -3, target: [0,0,0], camera: [5,2,0]}
    let ecfg = { maxH: 0.6, line: 3, target: this.width < 600 ? [-0.11,2.53,1.59] : [2.88,0.49,2.21], camera: this.width < 600 ? [10.98,-0.44,-25.66] : [9.77,-0.34,-15.64]}
    let tween1 = new TWEEN.Tween(scfg).to(ecfg, 2000).easing(TWEEN.Easing.Quadratic.Out).onUpdate(() => { 
      this.dotList3[0].mesh.material.uniforms.maxH.value = scfg.maxH
      this.camera.position.set(...scfg.camera)
      this.controls.target = new THREE.Vector3(...scfg.target)
      this.dotList3.map((val, idx) => {
        val.sDeg -= 0.03
        val.r -= 0.001
        if (val.ep[1] < scfg.line) {
          val.r -= 0.085 * val.r
        }
        val.r = val.r < 0 ? 0 : val.r
        let x = Math.cos(val.sDeg) * val.r + val.ep[0]
        let z = Math.sin(val.sDeg) * val.r + val.ep[2]
        val.mesh.position.set(x, val.ep[1], z)
      })
    }).onComplete(() => {
      this.dotList3.map(val => {
        if (val.ep) {
          val.mesh.position.set(...val.ep)
        }
      })
    }).start() 
    this.page2Tween.push(tween1) 
  }
  resetLightPage1_2 () {
    this.scene.environment = null      
    let light1 = this.lightGroup.getObjectByName('DirectionalLight1')
    light1.position.set(5,5,5)
    light1.intensity = 2
    let AmbientLight = this.lightGroup.getObjectByName('AmbientLight')
    AmbientLight.intensity = 3
    let arr = [
      {type: 'DirectionalLight', name: 'DirectionalLight2', color: 0xffffff, intensity: 1, position: [-4,2,-10]},
      {type: 'DirectionalLight', name: 'DirectionalLight3', color: 0x8899FF, intensity: 1, position: [5,5,-2]},
      {type: 'DirectionalLight', name: 'DirectionalLight4', color: 0x88FFCC, intensity: 1, position: [-5,5,5]},
    ]
    arr.map(val => {
      let light = new THREE.DirectionalLight(val.color, val.intensity)
      light.name = val.name
      light.position.set(...val.position)
      this.lightGroup.add(light)
    })
  }
  initPage3 () { // page3
    this.page3Group = new THREE.Group()
    this.page3Group.name = 'page3Group'
    this.page3Group.rotation.x = -0.1*Math.PI
    this.page3Group.position.set(-5.5,-1,0)
    this.scene.add(this.page3Group)
    let alphaMap = new THREE.TextureLoader().load(`${this.publicPath}image/opacity.png`)
    alphaMap.wrapS = THREE.RepeatWrapping
    alphaMap.wrapT = THREE.RepeatWrapping
    let alphaMap1 = new THREE.TextureLoader().load(`${this.publicPath}image/opacity2.png`)
    alphaMap1.repeat.set(1,1)
    let emissiveMap = new THREE.TextureLoader().load(`${this.publicPath}image/e.png`)
    let page2matList = {
      大地网格: { mapPath: 'bg.png', repeat: [35, 35], mat: new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 1.5, alphaMap: alphaMap1})},
      地球网格: { mat: new THREE.MeshStandardMaterial({color: 0xffffff, metalness: 0.99, roughness: 0.3, wireframe: true, transparent: true, alphaMap})},
      地球: { mapPath: 'earth.png', mat: new THREE.MeshStandardMaterial({color: 0xffffff,  metalness: 0.9, roughness: 0.5,emissive:0xffAAAA, emissiveMap})},
      底图: { mapPath: '底图.png', mat: new THREE.MeshStandardMaterial({color: 0x77FFFF, transparent: true, opacity: 0.4})},
      雷达框: { mapPath: '雷达框.png', mat: new THREE.MeshStandardMaterial({color: 0x3377ff, transparent: true, opacity: 0.6})},
      最内层: { mapPath: '最内层.png', mat: new THREE.MeshStandardMaterial({color: 0xffffff, transparent: true, opacity: 0.3})},
      旋转箭头: { mapPath: '旋转箭头.png', mat: new THREE.MeshStandardMaterial({color: 0xff5500, transparent: true, opacity: 1})},
      外2: { mapPath: '外2.png', mat: new THREE.MeshStandardMaterial({color: 0xffffff, transparent: true, opacity: 1})},
      最外层: { mapPath: '最外层.png', mat: new THREE.MeshStandardMaterial({color: 0xffffff, transparent: true, opacity: 0.3})},
      地球飞线: { mapPath: 'line2.png', mat: new THREE.MeshStandardMaterial({color: 0xffffff, transparent: true, opacity: 1, alphaTest: 0.001})},
      地球飞线1: { mapPath: 'line2.png', mat: new THREE.MeshStandardMaterial({color: 0x7777FF, transparent: true, opacity: 1, alphaTest: 0.001})},
    }
    for (let val in page2matList) {
      if (page2matList[val].mapPath) {
        let map = new THREE.TextureLoader().load(`${this.publicPath}image/${page2matList[val].mapPath}`)
        map.wrapS = THREE.RepeatWrapping
        map.wrapT = THREE.RepeatWrapping
        if (page2matList[val].repeat) {
          map.repeat.set(...page2matList[val].repeat)
        }
        page2matList[val].mat.map = map
      } 
    }
    this.page3dataList = {
      地球飞线: {name: 'TorusGeometry', renderOrder: 1, data: [2.2,0.005,4,50], rotation: [1.4,0.4,0], position: [0,0,0], mat: page2matList.地球飞线.mat},
      地球飞线1: {name: 'TorusGeometry', renderOrder: 2, data: [2.2,0.005,4,50], rotation: [1.1,0.6,0], position: [0,0,0], mat: page2matList.地球飞线1.mat},
      大地网格: {name: 'PlaneGeometry', renderOrder: 3, data: [30,30], position: [10,0,-0.22], mat: page2matList.大地网格.mat},
      底图: {name: 'PlaneGeometry', renderOrder: 4, data: [14,14], position: [0,-0.22,-0.2], mat: page2matList.底图.mat},
      雷达框: {name: 'PlaneGeometry', renderOrder: 5, data: [3,3], position: [0,0,-0.18], mat: page2matList.雷达框.mat},
      波浪线: {name: 'PlaneGeometry', renderOrder: 6, data: [6,6], position: [0,0,-0.16], mat: this.canvasMat1, rotation: [0,0,0]},
      最内层: {name: 'PlaneGeometry', renderOrder: 7, data: [6,6], position: [0,0,-0.14], mat: page2matList.最内层.mat},
      外2: {name: 'PlaneGeometry', renderOrder: 8, data: [7,7], position: [0,0,-0.12], mat: page2matList.外2.mat},
      旋转箭头: {name: 'PlaneGeometry', renderOrder: 9, data: [6,6], position: [0,0,-0.1], rotation: [0,0,0], mat: page2matList.旋转箭头.mat},
      最外层: {name: 'PlaneGeometry', renderOrder: 10, data: [8,8], position: [0,0,-0.08], mat: page2matList.最外层.mat},
      地球: {name: 'IcosahedronGeometry', renderOrder: 11, data: [2,5], mat:page2matList.地球.mat},
      地球网格: {name: 'IcosahedronGeometry', renderOrder: 12, data: [2.05,8], position: [0,0,0], mat: page2matList.地球网格.mat},
      切换文字: {name: 'PlaneGeometry', renderOrder: 12, data: [36,2], position: [0,0,0.1], mat: this.canvasMat2, rotation: [0,0,0]},
    }
    for (let i in this.page3dataList) {
      let val = this.page3dataList[i]
      let geo = new THREE[val.name](...val.data)
      let mesh = new THREE.Mesh(geo, val.mat)
      if (val.position) {
        mesh.position.set(...val.position)
      }
      if (val.rotation) {
        mesh.rotation.x = val.rotation[0]
        mesh.rotation.y = val.rotation[1]
        mesh.rotation.z = val.rotation[2]
      }
      mesh.renderOrder = val.renderOrder
      mesh.visible = false
      val.mesh = mesh
      this.page3Group.add(mesh)
    }
    this.page3Tween = []
    
    new RGBELoader()
    .setPath( `${this.publicPath}image/` )
    .load( 'ff.hdr', ( texture ) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      this.earthTexture = texture
    })
  }
  changeWaveDotMap () { 
    if (this.canvasMat1 && this.canvasMat1.map) {
      this.canvasMat1.map.needsUpdate = true
    }
  }
  setCanvasMap (canvas1, canvas2) {
    let map1 = new THREE.CanvasTexture( canvas1 ) // wave-toroidal
    this.canvasMat1.map = map1
    let map2 = new THREE.CanvasTexture( canvas2 ) // wave-toroidal
    this.canvasMat2.map = map2
  }
  changeText6 () {
    if (this.canvasMat2 && this.canvasMat2.map) {
      this.canvasMat2.map.needsUpdate = true
    }
  }
  setChangeName (cxText6, dot6, cxPhone6) {
    this.cxText6 = cxText6
    this.dot6 = dot6
    this.cxPhone6 = cxPhone6
  }
  movePage3_1 (type) {   // 进入服务页面
    let arr = ['earthGroup', 'starGroup', 'dotGroup1','dotGroup2', 'page3Group', 'page4Group']      
    this.removeGroup(arr)
    this.isMoveY = true  
    this.lightGroup.remove(this.lightGroup.getObjectByName('DirectionalLight2'))
    this.lightGroup.remove(this.lightGroup.getObjectByName('DirectionalLight3'))
    this.lightGroup.remove(this.lightGroup.getObjectByName('DirectionalLight4'))
    let light1 = this.lightGroup.getObjectByName('DirectionalLight1')
    light1.position.set(50,40,0)
    light1.intensity = 2
    let AmbientLight = this.lightGroup.getObjectByName('AmbientLight')
    AmbientLight.intensity = 3

    for (let i in this.page3dataList) {
      let val = this.page3dataList[i]
      val.mesh.visible = false
    }
    this.page3Group.visible = true
    if (type == 'down') { // 系统图标 --> 斜面地球
      if (this.earthTexture) this.scene.environment = this.earthTexture  
      this.dotList3.map(val => {
        val.r = Math.random() * 0.1
        val.move = Math.random() > 0.6 ? 1 : -1
      })
      
      let scfg = {}
      let ecfg = {} 
      let obj1 = new TWEEN.Tween(scfg).to(ecfg, 400).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        if (this.dotGroup3) this.dotGroup3.rotation.y -= 0.06
      }).onComplete(() => {
        let scfg1 = {count: 0, y: this.dotGroup3.rotation.y, target: [1.78,0.67,1.78], camera: [7.39,2.54,-12.76],}
        let ecfg1 = {count: 100, y: this.dotGroup3.rotation.y + 20, target: [0,0,0], camera: [12,0,12],} 
        let obj2 = new TWEEN.Tween(scfg1).to(ecfg1, 200).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
          if (this.dotGroup3) this.dotGroup3.rotation.y = scfg1.y
          this.camera.position.set(...scfg1.camera)
          this.controls.target = new THREE.Vector3(...scfg1.target)
          this.dotList3.map(val => {
            val.r += Math.random() * 1
            let x = Math.cos(val.sDeg) * val.r
            let z = Math.sin(val.sDeg) * val.r
            val.mesh.position.set(val.ep[0] + x,val.mesh.position.y * 1.05, val.ep[2] +z)
          })
        }).onComplete(() => {
          let scfg2 = {}
          let ecfg2 = {} 
          let obj3 = new TWEEN.Tween(scfg2).to(ecfg2, 400).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
            this.dotGroup3.rotation.y += 0.0005
          }).onComplete(() => {
            let scfg3 = {target: [0,0,0], r: -0.08, ro: 0.3,}
            let ecfg3 = {target: [5,1,0], r: -0.2, ro: 1,}
            this.controls.target = new THREE.Vector3(5,1,0)
            this.dotList3.map(val => {
              val.mesh.scale.set(0.2,0.2,0.2)
            })
            let obj4 = new TWEEN.Tween(scfg3).to(ecfg3, 200).easing(TWEEN.Easing.Quintic.Out).onUpdate(() => {
              this.dotList3[0].mesh.scale.set()
              this.dotGroup3.rotation.y += scfg3.ro
              this.dotGroup3.rotation.x += scfg3.ro
              this.dotList3.map(val => {
                let s = val.mesh.scale.x - val.mesh.scale.x  * 0.02
                val.mesh.scale.set(s,s,s)
                val.r += scfg3.r * val.r * 2
                let x = Math.cos(val.sDeg) * val.r
                let z = Math.sin(val.sDeg) * val.r
                let y = val.mesh.position.y + val.mesh.position.y * scfg3.r * 1.5
                val.mesh.position.set(x,y,z)
              })
            }).onComplete(() => {
              this.movePage3_2()
            }).start()
            this.page3Tween.push(obj4) 
          }).start()
          this.page3Tween.push(obj3) 
        }).start()
        this.page3Tween.push(obj2) 
      }).start()
      this.page3Tween.push(obj1) 
    } else if (type == 'up') { // 联系我们 --> 斜面地球
      let arr = ['dotGroup3'] 
      this.removeGroup(arr)   
      if (this.earthTexture) this.scene.environment = this.earthTexture  
      for (let i in this.page4dataList) {
        let val = this.page4dataList[i]
        val.mesh.visible = false
      }
      this.movePage3_2()
    }
  }
  movePage3_2 () {
    this.scene.remove(this.dotGroup3)
    this.dotGroup3 = null
    this.scene.remove(this.starGroup1)
    this.starGroup1 = null
    let camera = this.width < 600 ? [-28.19,-0.44,27.83] : [-12, 3, 15]
    this.camera.position.set(...camera)
    let target = this.width < 600 ? [-2.46,2.42,-2.06]: [0,-0.5,0]
    this.controls.target = new THREE.Vector3(...target)
    let mesh = this.page3dataList.大地网格.mesh
    let scfg = {x: 0}
    let ecfg = {x: 10}   
    mesh.visible = true
    mesh.material.alphaMap.offset.x = 0
    let obj1 = new TWEEN.Tween(scfg).to(ecfg, 500).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
      mesh.material.alphaMap.offset.x -= 0.025
    }).onComplete(() => {
      this.movePage3_3()
    }).start()
    this.page3Tween.push(obj1) 
    this.movePage3_6()
  }
  movePage3_3 () {
    let mesh = this.page3dataList.底图.mesh
    let scfg = {o: 0,}
    let ecfg = {o: mesh.material.opacity,}   
    mesh.visible = true
    mesh.material.opacity = 0
    let obj1 = new TWEEN.Tween(scfg).to(ecfg, 500).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
      mesh.material.opacity = scfg.o 
    }).onComplete(() => {
      let sc1 = {o: 0.7}
      let ec1 = {o: 0.3}
      let tweenObj = new TWEEN.Tween(sc1).to(ec1, 2000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        mesh.material.opacity = sc1.o
      }).repeat(Infinity).yoyo(true).start() 
      this.page3Tween.push(tweenObj) 
      this.movePage3_4()
    }).start()
    this.page3Tween.push(obj1) 
  }
  movePage3_4 () {
    let mesh1 = this.page3dataList.最内层.mesh
    let mesh2 = this.page3dataList.外2.mesh
    let mesh3 = this.page3dataList.最外层.mesh
    let arr = [mesh1, mesh2, mesh3]
    arr.map(val => {
      val.scale.set(0,0,0)
      val.visible = true
    })    
    let scfg1 = {o1: 0, scale: 0}
    let ecfg1 = {o1: mesh1.material.opacity, scale: 1}   
    let obj1 = new TWEEN.Tween(scfg1).to(ecfg1, 500).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
      mesh1.material.opacity = scfg1.o1 
      mesh1.scale.set(scfg1.scale,scfg1.scale,scfg1.scale)
      mesh1.rotation.z += 0.0005
    }).onComplete(() => {
    }).start()
    this.page3Tween.push(obj1) 
    let scfg2 = {o2: 0, scale: 0}
    let ecfg2 = {o2: mesh2.material.opacity, scale: 1}   
    let obj2 = new TWEEN.Tween(scfg2).to(ecfg2, 500).easing(TWEEN.Easing.Quintic.In).delay(400).onUpdate(() => {
      mesh2.material.opacity = scfg2.o2 
      mesh2.scale.set(scfg2.scale,scfg2.scale,scfg2.scale)
      mesh1.rotation.z += 0.0005
      mesh2.rotation.z -= 0.0005
    }).onComplete(() => {
    }).start()
    this.page3Tween.push(obj2) 
    let scfg3 = {o3: 0, scale: 0}
    let ecfg3 = {o3: mesh3.material.opacity, scale: 1}   
    let obj3 = new TWEEN.Tween(scfg3).to(ecfg3, 500).easing(TWEEN.Easing.Quintic.In).delay(600).onUpdate(() => {
      mesh3.material.opacity = scfg3.o3
      mesh3.scale.set(scfg3.scale,scfg3.scale,scfg3.scale)
      mesh1.rotation.z += 0.0005
      mesh2.rotation.z -= 0.0005
      mesh3.rotation.z += 0.0005
    }).onComplete(() => {
      this.movePage3_5()
    }).start()
    this.page3Tween.push(obj3) 
  }
  movePage3_5 () {
    let mesh1 = this.page3dataList.最内层.mesh
    let mesh2 = this.page3dataList.外2.mesh
    let mesh3 = this.page3dataList.最外层.mesh
    let scfg = {x: 0.0005}
    let ecfg = {x: 0.7}   
    let obj1 = new TWEEN.Tween(scfg).to(ecfg, 1000).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
      mesh1.rotation.z += scfg.x
      mesh2.rotation.z -= scfg.x
      mesh3.rotation.z += scfg.x
    }).onComplete(() => {
    }).start()
    this.page3Tween.push(obj1) 
    let obj2 = new TWEEN.Tween({o:0}).to({o:1}, 500).easing(TWEEN.Easing.Linear.None).delay(800).onUpdate(() => {
      mesh1.rotation.z += 0.8
      mesh2.rotation.z -= 0.7
      mesh3.rotation.z += 0.7
    }).start() 
    this.page3Tween.push(obj2) 
    let scfg2 = {x: 0.7}
    let ecfg2 = {x: 0.0005}   
    let obj3 = new TWEEN.Tween(scfg2).to(ecfg2, 400).easing(TWEEN.Easing.Quintic.Out).delay(1100).onUpdate(() => {
      mesh1.rotation.z += scfg2.x
      mesh2.rotation.z -= scfg2.x
      mesh3.rotation.z += scfg2.x
    }).onComplete(() => {
    }).start()
    this.page3Tween.push(obj3) 
    let scfg1 = {x: 0.1}
    let ecfg1 = {x: 0.005}   
    let obj = new TWEEN.Tween(scfg1).to(ecfg1, 60000).easing(TWEEN.Easing.Linear.None).delay(800).onUpdate(() => {
      mesh1.rotation.z += 0.0005
      mesh2.rotation.z -= 0.0005
      mesh3.rotation.z += 0.0005
    }).repeat(Infinity).start() 
    this.page3Tween.push(obj) 
  }
  movePage3_6 () {
    let earthMesh = this.page3dataList.地球.mesh
    earthMesh.scale.set(0,0,0)   
    earthMesh.visible = true  
    let scfg1 = {scale: 0, y: 0}
    let ecfg1 = {scale: 0.8, y: 0.5}  
    let obj1 = new TWEEN.Tween(scfg1).to(ecfg1, 800).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
      earthMesh.rotation.y += scfg1.y*Math.PI   
      earthMesh.scale.set(scfg1.scale, scfg1.scale, scfg1.scale)    
    }).onComplete(() => {
    }).start()  
    this.page3Tween.push(obj1) 
    let scfg = {scale: 0.8, y: 0.5}
    let ecfg = {scale: 1, y: 0.001}   
    let obj2 = new TWEEN.Tween(scfg).to(ecfg, 200).delay(800).easing(TWEEN.Easing.Quintic.Out).onUpdate(() => {
      earthMesh.rotation.y += scfg.y*Math.PI   
      earthMesh.scale.set(scfg.scale, scfg.scale, scfg.scale)    
    }).onComplete(() => {
      let obj = new TWEEN.Tween({y:0}).to({y:0}, 60000).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
        earthMesh.rotation.y += 0.001*Math.PI   
      }).repeat(Infinity).start() 
      this.page3Tween.push(obj)      
      let mesh1 = this.page3dataList.地球网格.mesh
      let mesh2 = this.page3dataList.地球飞线.mesh
      let mesh3 = this.page3dataList.地球飞线1.mesh
      mesh1.visible = true
      mesh2.visible = true
      mesh3.visible = true
      mesh2.material.map.offset.x = 0.5
      mesh3.material.map.offset.x = 0.5
      let sc2 = {offsetX: 0}
      let ec2 = {offsetX: 1}
      let obj4 = new TWEEN.Tween(sc2).to(ec2, 7000).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
        mesh1.material.alphaMap.offset.x -= sc2.offsetX > 0.1 ? 0.00642 : 0
        mesh2.material.map.offset.x += 0.003
        mesh3.material.map.offset.x += 0.005
      }).repeat(Infinity).start() 
      this.page3Tween.push(obj4)
      this.movePage3_7()
    }).start()  
    this.page3Tween.push(obj2) 
  }
  movePage3_7 () {  
    let mesh = this.page3dataList.旋转箭头.mesh
    let scfg = {o:0, z:1,}
    let ecfg = {o:1, z: 1.5,}   
    mesh.visible = true
    mesh.rotation.z = 1
    let obj1 = new TWEEN.Tween(scfg).to(ecfg, 800).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
      mesh.material.opacity = scfg.o
      mesh.rotation.z = scfg.z
    }).onComplete(() => {
      let sc1 = {z: 1.5, opacity: 0.1}
      let ec1 = {z: 0, opacity: 1}
      let meshText = this.page3dataList.切换文字.mesh
      let obj = new TWEEN.Tween(sc1).to(ec1, 800).delay(1200).easing(TWEEN.Easing.Quintic.InOut).onUpdate(() => {
        mesh.rotation.z = sc1.z
        meshText.rotation.z = sc1.z
        meshText.material.opacity = sc1.opacity
      }).repeat(Infinity).yoyo(true).start()
      this.page3Tween.push(obj) 
      this.movePage3_8()
    }).start()
    this.page3Tween.push(obj1) 
    this.setTimeoutIdx = setTimeout(() => {
      let idx = 0
      let meshText = this.page3dataList.切换文字.mesh
      meshText.visible = true
      if (this.cxText6) {
        this.dot6.changeName(idx)
        if (this.width < 600) {
          this.cxPhone6.changeName(idx)
          this.cxText6.changeName(-1)
        } else {
          this.cxText6.changeName(idx)
        }
      }
      this.textInterval = setInterval(() => {
        if (this.cxText6) {
          idx++
          if (idx > 3) idx = 0
          this.dot6.changeName(idx)
          if (this.width < 600 && this.cxPhone6) {
            this.cxPhone6.changeName(idx)
          } else {
            this.cxText6.changeName(idx)
          }
        }
      }, 4000)
    }, 2400)
  }
  movePage3_8 () {
    let mesh = this.page3dataList.波浪线.mesh
    let scfg = {o:0,}
    let ecfg = {o:1,}   
    mesh.material.opacity = 0
    mesh.visible = true
    let obj1 = new TWEEN.Tween(scfg).to(ecfg, 1000).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
      mesh.material.opacity = scfg.o
    }).onComplete(() => {
    }).start()
    this.page3Tween.push(obj1) 
  }
  initPage4 () { // page4    
    let planeGeo = new THREE.PlaneGeometry(60,60, 1)
    let gridMap1 = new THREE.TextureLoader().load(`${this.publicPath}image/bg.png`)
    gridMap1.wrapS = gridMap1.wrapT = THREE.RepeatWrapping
    gridMap1.repeat.set(120, 120)
    let gridMat1 = new THREE.MeshBasicMaterial({color: 0xffffff, map: gridMap1, transparent: true, opacity: 2})
    let gridMesh1 = new THREE.Mesh(planeGeo, gridMat1)
    gridMesh1.rotation.x = -0.5*Math.PI
    gridMesh1.position.y = -0.2
    gridMesh1.renderOrder = 1
    
    let geoLogo = new THREE.PlaneGeometry(2,2)
    let matLogo = new THREE.MeshBasicMaterial({transparent: true, opacity: 0.5, map: new THREE.TextureLoader().load(`${this.publicPath}image/logo.png`)})
    let meshLogo = new THREE.Mesh(geoLogo, matLogo)
    meshLogo.rotation.x = -0.5*Math.PI
    meshLogo.position.set(-4.17,-0.01,-0.35)
    meshLogo.renderOrder = 2

    this.page4Group = new THREE.Group()
    this.page4Group.name = 'page4Group'
    this.scene.add(this.page4Group)

    let line0 = this.initLine(10)
    let line1 = this.initLine(1.2)
    this.flow0 = new Flow(line0)
    this.flow0.object3D.visible = false
    this.page4Group.add( this.flow0.object3D )
    
    this.flow1 = new Flow(line1)
    this.flow1.object3D.visible = false
    this.page4Group.add( this.flow1.object3D )

    this.page4dataList = {
      地面网格点: {mesh: gridMesh1},
      面片logo: {mesh: meshLogo},
    }
    for (let i in this.page4dataList) {
      let val = this.page4dataList[i]
      val.mesh.visible = false
      this.page4Group.add(val.mesh)
    }
    this.page4Tween = []
    
    this.page4DotList = []
    for (let i = 0; i < 4; i++) {
      let group = new THREE.Group()
      let geo1 = new THREE.PlaneGeometry(1.3,1.3)
      let mat1 = new THREE.MeshBasicMaterial({transparent: true, opacity: 1, map: new THREE.TextureLoader().load(`${this.publicPath}image/point.png`)})
      let mesh1 = new THREE.Mesh(geo1, mat1)
      mesh1.renderOrder = 2
      mesh1.rotation.x = -0.5*Math.PI
      group.add(mesh1)
      let geo2 = new THREE.PlaneGeometry(0.2,0.2)
      let mat2 = new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0.3})
      let mesh2 = new THREE.Mesh(geo2, mat2)
      mesh2.rotation.x = -0.5*Math.PI
      mesh2.renderOrder = 1
      group.add(mesh2)
      let obj = {group: group, border: mesh1, dot: mesh2, list: []}
      this.page4DotList.push(obj)
    }
    this.page4DotList[0].list = [[-7.5,0,0], [-3,0,1], [1,0,0],[3,0,-1], [100,0,0]]
    this.page4DotList[1].list = [[-4,0,-1], [-2,0,0],[2,0,1],[4,0,2], [100,0,0]]
    this.page4DotList[2].list = [[-6,0,2], [-1,0,3],[3,0,3],[6,0,3], [100,0,0]]
    this.page4DotList[3].list = [[-5,0,-3], [-2,0,-2],[4,0,-3],[7,0,-2], [100,0,0]]
    this.page4DotList.map(val => {
      val.group.position.set(...val.list[4])
      this.page4Group.add(val.group)
    })
  }
  initPage4Dot () {
    let index = 0
    let delayIdx = 0
    this.page4DotList.map(val => {
      val.group.position.set(...val.list[4])
    })
    this.page4Interval = setInterval(() => {
      delayIdx++ 
      if (delayIdx > 7) delayIdx = 0
      if (delayIdx < 5) {
        index++
        if (index > 4) index = 0
        this.shwoDot(this.page4DotList, index)  
      }
    }, 800);
  }
  async shwoDot (dotList, index) {
    for (let i = 0 ; i < dotList.length; i++) {
      await new Promise((resolve) => {
        setTimeout(resolve, 200);
      })
      let obj = dotList[i]
      obj.group.position.set(obj.list[index][0],-0.1,obj.list[index][2])
      let sc = {o: 0, size: 0.1}
      let ec = {o: 1, size: 1}
      new TWEEN.Tween(sc).to(ec, 300).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
        obj.border.scale.set(sc.size, sc.size, sc.size)
      }).start() 
      let sc1 = {o: 0, size: 0.1, o1: 1}
      let ec1 = {o: 0.2, size: 1, o1: 0.2}
      new TWEEN.Tween(sc1).to(ec1, 500).delay(300).easing(TWEEN.Easing.Quintic.In).onUpdate(() => {
        obj.dot.material.opacity = sc1.o
        obj.dot.scale.set(sc1.size, sc1.size, sc1.size)
      }).start() 
    }
  }
  initLine (len) {
    let group = new THREE.Group()
    let map = new THREE.TextureLoader().load(`${this.publicPath}image/line3.png`)
    map.wrapS = THREE.RepeatWrapping
    map.wrapT = THREE.RepeatWrapping
    map.repeat.y = 2
    map.rotation = Math.PI / 2;
    map.offset.set(0.5, 0.5);
    let mat = new THREE.MeshBasicMaterial({ map: map, color: 0xffffff, opacity: 2})
    let curve = this.getPath([[0,0,0], [len,0,0]])
    let geo = new THREE.TubeGeometry(curve, len * 30, 0.008, 4, false)
    let mesh = new THREE.Mesh(geo, mat)
    mesh.position.y = 0.1
    group.add(mesh)
    return group
  }
  movePage4_1 (type) { // 进入到联系我们
    let arr = ['earthGroup', 'starGroup', 'starGroup1', 'dotGroup1', 'dotGroup2', 'dotGroup3', 'page3Group', 'page4Group']      
    this.isMoveY = false
    this.removeGroup(arr)
    this.page4Group.visible = true
    this.flow0.object3D.visible = false
    this.flow1.object3D.visible = false
    this.page4dataList.地面网格点.mesh.visible = true
      
    let scfg = {o:0, target: [0,-0.5,0], camera: [-12, 3, 15]}
    let ecfg = {o:1, target: this.width < 600 ? [-1.19,-0.09,3.43]:[0,0,-0.4], camera: this.width < 600 ? [-1.19,52.77,4.61]:[0,18,0]} 
    let obj1 = new TWEEN.Tween(scfg).to(ecfg, 500).easing(TWEEN.Easing.Quintic.Out).onUpdate(() => {
      this.camera.position.set(...scfg.camera)
      this.controls.target = new THREE.Vector3(...scfg.target)
    }).onComplete(() => {
      this.movePage4_2()
    }).start()
    this.page4Tween.push(obj1)
  }
  movePage4_2 () { 
    const curve0 = this.getPath(data0)
    curve0.curveType = 'catmullrom';
    curve0.closed = false;
    this.flow0.updateCurve( 0, curve0 )
    this.flow0.object3D.visible = true
    let sc = {}
    let ec = {}
    let obj1 = new TWEEN.Tween(sc).to(ec, 1400).easing(TWEEN.Easing.Linear.None).onUpdate(() => {
      if (this.flow0) {
        this.flow0.moveAlongCurve( 0.008 )
      }
    }).onComplete(() => {
      this.movePage4_3()
    }).start()
    this.page4Tween.push(obj1)
  }
  movePage4_3 () {     
    const curve01 = this.getPath(data1)
    curve01.curveType = 'catmullrom';
    curve01.closed = false;
    this.flow0.updateCurve( 0, curve01 )
    const curve1 = this.getPath(data2)
    curve1.curveType = 'catmullrom';
    curve1.closed = true;
    this.flow1.object3D.visible = true
    this.flow1.updateCurve( 0, curve1 )

    this.page4dataList.面片logo.mesh.visible = true
    this.initPage4Dot()
    let sc = {}
    let ec = {}
    let obj1 = new TWEEN.Tween(sc).to(ec, 500).easing(TWEEN.Easing.Linear.None).repeat(Infinity).onUpdate(() => {
      if (this.flow0 && this.flow1) {
        this.flow0.moveAlongCurve( 0.003 )
        this.flow1.moveAlongCurve( 0.003 )
      }
    }).start()
    this.page4Tween.push(obj1)
  }
}