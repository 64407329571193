<template>
  <div class="cx_phone_page5_text hide" ref="cx_phone_page5_text" v-show="index >= 0">
    <p class="titleName">{{name}}</p>
    <p class="titleValue">{{value}}+</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      nameList: [
        {name: '', value: 60},
        {name: '', value: 80},
        {name: '', value: 18},
        {name: '', value: 130},
      ],
      index: 0,
    }
  },
  computed: {
    name () {
      if (this.index >= 0 && this.index < this.nameList.length) {
        return this.nameList[this.index].name
      } 
      return ''
    },
    value () {
      if (this.index >= 0 && this.index < this.nameList.length) {
        return this.nameList[this.index].value
      } 
      return ''
    }
  },
  mounted () {
    this.getData()
  }, 
  methods: {
    getData () {
      this.nameList[0].name = this.$t('server.t1')
      this.nameList[1].name = this.$t('server.t2')
      this.nameList[2].name = this.$t('server.t3')
      this.nameList[3].name = this.$t('server.t4')
    },
    changeName (idx) {
      this.index = idx
    },
  },
  watch: {
    language () {
      this.getData()
    }
  }
}
</script>

<style lang="stylus" scope>
.cx_phone_page5_text
  position absolute 
  width 90vw
  left 5vw
  top 7rem
  text-align center
  .titleName
    font-size 1rem
    color #fff
    margin-bottom 0.5rem
    line-height 2rem
  .titleValue
    font-size 2rem
    color $c8
    margin-bottom 6rem
.show
  display block
  .titleName, .titleValue
    opacity 0
  .titleName
    animation textUp 0.8s 0.2s ease forwards
  .titleValue
    animation textUp 0.8s 0.4s ease forwards
.hide
  .titleName, .titleValue
    opacity 0
</style>