<template>
  <div class="yy-home" id="yyHome">    
    <div class="bg">
      <div class="starPage" v-if="bgShow.showStar">
        <div class="bgColor" v-show="bgShow.showEarthBg"></div>
        <div class="starBox" :class="{rotateStar: bgShow.rotateStar}">
          <p class="star" v-for="(item, index) in starList" :key="'star'+index" :style="`width:${item.width}px;height:${item.width}px;left:${item.left}%;top:${item.top}%;`"></p>
        </div>
        <div class="comet comet1"></div>
        <div class="comet comet2"></div>
        <div class="comet comet3"></div>
        <div class="comet comet4"></div>
        <div class="comet comet5"></div>
      </div>
    </div>
    <wave-dot ref="waveDot" @change="changeWaveDotMap"></wave-dot>
    <canvas id='modelBox' class="modelBox"></canvas>
    <div class="pageHeight" @scroll="pageScroll($event)">
      <div class="heightContent" ref="heightContent"></div>
    </div>
    <cx-header @clickMenu="clickMenu"></cx-header>
    <cx-text1 v-if="textPageList[0].show" :class="textPageList[0].class"></cx-text1>
    <cx-text2 v-if="textPageList[1].show" :class="textPageList[1].class"></cx-text2>
    <cx-text3 v-if="textPageList[2].show" :class="textPageList[2].class"></cx-text3>
    <cx-text4 v-if="textPageList[3].show" :class="textPageList[3].class"></cx-text4>
    <cx-text5 v-if="textPageList[4].show" :class="textPageList[4].class"></cx-text5>
    <cx-dot6 ref="dot6"></cx-dot6>
    <cx-text6 ref="cxText6" @change="changeText6"></cx-text6>
    <cx-phone6 v-if="pageWidth < 600" :class="textPageList[5].class" ref="cxPhone6"></cx-phone6>
    <cx-text7 v-if="textPageList[6].show" :class="textPageList[6].class"></cx-text7>
    <!-- <div class="getTarge" @click="getTarget()">获取位置信息</div> -->
    
    <div class="bottomICP"> 
      <a class="icp" href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023027003号-1</a>    
      <a class="icp" href="https://beian.mps.gov.cn/#/query/webSearch?code=42018502007129" rel="noreferrer" target="_blank"><img class="icp-icon" src="@assets/images/备案图标.png"><span>鄂公网安备42018502007129</span></a>
      <p class="copyright">{{$t('home.copyright') + $t('home.title')}}</p>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import YY from './yy.js'
import * as THREE from 'three'
import cxHeader from './cxHeader.vue'
import cxText1 from '../textPage/text1.vue'
import cxText2 from '../textPage/text2.vue'
import cxText3 from '../textPage/text3.vue'
import cxText4 from '../textPage/text4.vue'
import cxText5 from '../textPage/text5.vue'
import cxText6 from '../textPage/text6.vue'
import cxText7 from '../textPage/text7.vue'
import cxDot6 from '../textPage/dot6.vue'
import cxPhone6 from '../textPage/phoneText6.vue'
import waveDot from  './waveDot.vue'
import dadaString from '@/components/base/dada-string.vue'
export default {
  data () {
    return {
      starList: [],
      bgShow: {
        showStar: true,
        showEarthBg: true,
        rotateStar: false,
      },
      scrollTop: 0,
      scrollType: '',
      pageList: [
        {name: 'page1', top: 0, currentIdx: 0, funThree: 'movePage1_0', funDown: '', funUp: 'upHome'},
        {name: 'page1', top: 800, currentIdx: 1, funThree: 'movePage1_1',  funDown: 'hideEarthBg', funUp: 'upPage2'},
        {name: 'page2', top: 1600, currentIdx: 2, funThree: 'movePage2_1',  funDown: 'downPage3', funUp: 'upPage3'},
        {name: 'page2', top: 2400, currentIdx: 3, funThree: 'movePage2_4',  funDown: 'downPage4', funUp: 'upPage4'},
        {name: 'page2', top: 3200, currentIdx: 4, funThree: 'movePage2_6',  funDown: 'downPage5', funUp: 'showBgStar'},
        {name: 'page3', top: 4000, currentIdx: 5, funThree: 'movePage3_1', funDown: 'setCanvasMap', funUp: 'upPage6'},
        {name: 'page4', top: 4800, currentIdx: 6, funThree: 'movePage4_1',  funDown: 'downPage7', funUp: ''},
        {name: 'page4', top: 5600, currentIdx: 7, funThree: '',  funDown: '', funUp: ''},
      ],
      textPageList: [
        {name: 'text1', show: true, class: ''},
        {name: 'text2', show: false, class: ''},
        {name: 'text3', show: false, class: ''},
        {name: 'text4', show: false, class: ''},
        {name: 'text5', show: false, class: ''},
        {name: 'text6', show: false, class: ''},
        {name: 'text7', show: false, class: ''},
      ]
    }
  },
  computed: {
    ...mapGetters(['currentIdx'])
  },
  created () {
    this.initStar()
  },
  mounted () {
    this.init()  
    console.log('武汉晨兴可视科技有限公司竭诚为您服务，请联系客服！微信：18064034662')
    // this.getTextString()
  },
  methods: {
    getTextString () {
      let data = {
        home: {
          lang: 'EN',
          scrollBottom: '向下滚动查看更多',
          title1: '我们拥有',
          title2: '专业的',
          title3: '技术团队，竭力为您提供',
          title4: '优质服务',
          title5: '为您面临的问题量身定制适合的解决方案',
          title: '武汉晨兴可视科技有限公司'
        },
        menu: {
          home: '首页',
          product: '产品',
          services: '服务',
          contact: '联系我们',
        },
        product: {
          title1: '管理系统类',
          text1: '随着云计算和移动通讯技术的发展，业务系统是现代化办公的核心组成部分，对于日常工作流程、提升效率和企业管理有着极其重要的作用。',
          title2: "应用场景",
          titleTip2: "[ 支持多终端适配 ]",
          t1: "业务流程管理",
          t2: "物资管理",
          t3: "销售管理",
          t4: "任务管理",
          t5: "能耗管理",
          t6: "人力资源管理",
          title2_1: "数字化建设",
          text2_1: "基于信息技术飞速发展，国家政策大力扶持的背景，数字孪生、数据可视、智慧管控等技术融入人们的工作和生活已经是大势所趋。我们有着大量可视化大屏建设项目经验，将竭尽全力为您打造符合您需求的可视化数据大屏。",
          title2_2: "应用场景",
          t2_1: "内部汇报",
          t2_2: "大型活动或展览",
          t2_3: "领导驾驶舱",
          t2_4: "数字孪生",
          title3_1: "应用/网站建设",
          text3_1: "对需求反复打磨，力求实现一个简单易用、高效便捷、更加安全的应用，并且提供一站式的解决方案，帮助您打造一个专业、现代化、用户友好的网站。",
          title3_2: "应用场景",
          titleTip3: "[ 支持多终端适配 ]",
          t3_1: "小程序",
          t3_2: "应用类软件",
          t3_3: "官网建设",
          t3_4: "商城网站搭建",
          t3_5: "工具类网站建设",
        },
        server: {
          t1: '我们团队曾经服务过的客户',
          t2: '客户业务覆盖地区及国家',
          t3: '服务过的行业',
          t4: '成功案例',
        },
        contact: {
          contact: '联系我们',
          tel: '电话：',
          email: '邮箱：',
          qq: 'QQ：',
          taobao: '淘宝：',
          vx: '（微信同号）',
          copy: '复制成功',
          back: '点击返回首页',
          contact1: '请联系客服：18064034662',
        }
      }
      let str = ``
      for(let i in data) {
        let obj = data[i]
        for (let j in obj) {
          str += obj[j]
        }
      }
      let arr = []
      let arr1 = str.split('')
      let b = ``
      arr1.map(val => {
        if (b.indexOf(val) <= 0) {
          b += val
        }
      })
      console.log(str)
      console.log(b)
      console.log(b.indexOf('武'))
    },
    initStar () { // css星星的添加
      let total = 150
      for (let i = 0; i < total; i++) {
        let width = Math.max(Math.random()*10, 5)
        let left = Math.random()* 100
        let top = Math.random()* 100
        this.starList.push({width, top, left})
      }
    },
    init () {
      this.yy = new YY('yyHome', {
        lightList: [
          {type: 'AmbientLight', name: 'AmbientLight', color: 0xffffff, intensity: 5},
          {type: 'DirectionalLight', name: 'DirectionalLight1', color: 0xffffff, intensity: 1, position: [5,5,5]},
          {type: 'DirectionalLight', name: 'DirectionalLight2', color: 0xffffff, intensity: 2, position: [-4,2,-10]},
          {type: 'DirectionalLight', name: 'DirectionalLight3', color: 0x8899FF, intensity: 1, position: [5,5,-2]},
          {type: 'DirectionalLight', name: 'DirectionalLight4', color: 0x88FFCC, intensity: 1, position: [-5,5,5]},
        ],
        showLightBox: false,
      })
      setTimeout(() => {
        this.yy.setCanvasMap(this.$refs.waveDot.canvas, this.$refs.cxText6.canvas)  
        this.yy.setChangeName(this.$refs.cxText6, this.$refs.dot6, this.pageWidth < 600 ? this.$refs.cxPhone6 : null)
      }, 200);
    },
    clickMenu (item) { // 点击菜单切换
      if (item.name === 'home') {
        $('.pageHeight').animate({scrollTop: 0}, 1000)
      } else if (item.name === 'product') {
        $('.pageHeight').animate({scrollTop: this.pageList[2].top + 10}, 1000)
      } else if (item.name === 'services') {
        $('.pageHeight').animate({scrollTop: this.pageList[5].top + 10}, 1000)
      } else if (item.name === 'contact') {
        $('.pageHeight').animate({scrollTop: this.pageList[6].top + 10}, 1000)
      }
    },
    pageScroll (e) {
      let scrollTop = Math.abs(this.$refs.heightContent.getBoundingClientRect().top)
      this.scrollType = this.scrollTop - scrollTop > 0 ? 'up' : 'down'
      let obj = null
      for (let i = 0; i < this.pageList.length - 1; i++) {
        let pageObj = this.pageList[i]
        if (scrollTop >= pageObj.top && scrollTop < this.pageList[i + 1].top && this.currentIdx != pageObj.currentIdx) {
          obj = pageObj
        }
      }
      if (obj) {
        this.$store.commit('set_currentIdx', obj.currentIdx)
        if (this.scrollType === 'down') {
          this.$nextTick(() => {
            if (obj.funDown) {
              // console.log(obj.funDown)
              this[obj.funDown](obj, this.scrollType);
            }
          })          
        } else {
          if (obj.funUp) {
              // console.log(obj.funUp)
            this[obj.funUp](obj, this.scrollType);
          }
        }
        this.$nextTick(() => {
          this.yy[obj.funThree] ? this.yy[obj.funThree](this.scrollType) : ''
        })
      }
      this.scrollTop = scrollTop
    },
    upHome (obj, type) {
      this.textPageList[1].class = 'hideUp'
      this.textPageList[0].show = true
      setTimeout(() => {
        this.bgShow.showEarthBg = true
        this.textPageList[1].show = false
      }, 1000);
    },
    hideEarthBg (obj, type) {
      this.bgShow.showEarthBg = false  
      this.textPageList[0].show = false
      this.textPageList[1].show = true
      this.textPageList[1].class = 'show'
    },
    upPage2 (obj, type) {
      this.textPageList[1].show = true
      this.textPageList[1].class = 'show'
      this.textPageList[2].class = 'hideUp'
      setTimeout(() => {
        this.textPageList[2].show = false
      }, 1000);
    },
    downPage3 () {
      this.textPageList[1].class = 'hideDown'
      this.textPageList[2].show = true
      this.textPageList[2].class = 'show'
      setTimeout(() => {
        this.textPageList[1].show = false
      }, 1000);
    },
    upPage3 () {
      this.textPageList[2].show = true
      this.textPageList[2].class = 'show'
      this.textPageList[3].class = 'hideUp'
      setTimeout(() => {
        this.textPageList[3].show = false
      }, 1000);
    },
    downPage4 () {
      this.textPageList[2].class = 'hideDown'
      this.textPageList[3].show = true
      this.textPageList[3].class = 'show'
      setTimeout(() => {
        this.textPageList[2].show = false
      }, 1000);
    },
    upPage4 () {
      this.textPageList[3].show = true
      this.textPageList[3].class = 'show'
      this.textPageList[4].class = 'hideUp'
      setTimeout(() => {
        this.textPageList[4].show = false
      }, 1000);
    },
    downPage5 () {
      this.textPageList[3].class = 'hideDown'
      this.textPageList[4].show = true
      this.textPageList[4].class = 'show'
      setTimeout(() => {
        this.textPageList[3].show = false
      }, 1000);
    },
    showBgStar () {
      this.bgShow.showStar = true
      this.textPageList[4].show = true
      this.textPageList[4].class = 'show'
      this.textPageList[5].class = 'hideUp'
    },
    setCanvasMap (obj, type) {
      this.textPageList[4].class = 'hideDown'
      this.textPageList[5].class = 'show'
      setTimeout(() => {
        this.bgShow.rotateStar = true
        this.textPageList[4].show = false
      }, 1500);
      setTimeout(() => {
        this.bgShow.rotateStar = false
        this.bgShow.showStar = false
      }, 2500); 
    },
    upPage6 () {
      this.textPageList[5].show = true
      this.textPageList[5].class = 'show'
      this.textPageList[6].class = 'hideUp'
      setTimeout(() => {
        this.textPageList[6].show = false
      }, 1000);
    },
    downPage7 () {
      this.textPageList[6].show = true
      this.textPageList[6].class = 'show'
      this.textPageList[5].class = 'hideUp'
    },
    getTarget () {
      if (this.yy) {
        let c = this.yy.camera.position
        let t = this.yy.controls.target
        let camera = [c.x.toFixed(2),c.y.toFixed(2),c.z.toFixed(2) ]
        let target = [t.x.toFixed(2),t.y.toFixed(2),t.z.toFixed(2) ]
        console.log('camera', camera.join(','))
        console.log('target', target.join(','))
      }
    },
    changeWaveDotMap () {
      if (this.yy) {
        this.yy.changeWaveDotMap()
      }
    },
    changeText6 () {
      if (this.yy) {
        this.yy.changeText6()
      }
    },
  },
  beforeDestroy () {
    if (this.yy) {
      this.yy.destroyWindowResize()
    }
  },
  components: {dadaString, waveDot, cxHeader, cxText1, cxText2, cxText3, cxText4, cxText5, cxText6, cxDot6, cxText7, cxPhone6}
}
</script>
<style scoped lang="stylus">
.yy-home
  width 100vw
  height 100vh
  overflow hidden  
  .getTarge
    position absolute
    top 100px
    left 20px
    z-index 5
    color rgba(255,255,255,0.6)
    cursor pointer
    &:hover
      color #fff    
  .bg, .modelBox, .pageHeight
    position absolute
    top 0
    left 0
    width 100%
    height 100%
  .bg
    background-color #000011
    z-index 0
    overflow hidden
    .starPage
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      overflow hidden
    .starBox
      position absolute
      top 0
      left 0
      width 100%
      height 100%
    .rotateStar
      transform-origin 20% 60%
      animation rotateStar 20s linear infinite
    .bgColor
      width 68vh
      height 68vh
      position fixed
      bottom 50%
      left 50%
      transform translate3d(-50%,50%, 0)
      background-image radial-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%, #3344ff 32%, #5588ff 42%, rgba(100,150,255,0.3) 45%, rgba(0,0,27,0) 70%, rgba(0,0,0,0))
      animation sunRund 3s linear infinite
    .star
      position absolute
      background-image radial-gradient(#fff,#38f 20%, rgba(0,0,0,0) 85%)
      &:nth-of-type(3n)
        animation star1 3s ease infinite
      &:nth-of-type(3n + 1)
        animation star1 7s ease infinite
      &:nth-of-type(3n + 2)
        animation star1 5s ease infinite
    .comet
      position absolute
      width 200px
      height 3px
      z-index -1
      opacity 0.6
      border-radius 5px 5px 50% 50%
      transform rotate(-45deg)
      background-image url(../../assets/images/comet.png)
      background-size 100% 100%
    .comet1
      top -50vh
      left 150vh
      opacity 0.7
      animation comet 5s linear infinite
    .comet2
      width 240px
      height 4px
      top -50vh
      left 180vh
      opacity 0.8
      animation comet2 5s .2s linear infinite
    .comet3
      top -50vh
      left 200vh
      opacity 0.3
      animation comet3 5s linear infinite
    .comet4
      top -50vh
      left 240vh
      width 220px
      opacity 0.6
      animation comet4 5s .4s linear infinite
    .comet5
      top -50vh
      left 100vh
      width 180px
      opacity 0.4
      animation comet5 5s 0.6s linear infinite
  .modelBox
    z-index 1
  .contentBox
    position absolute
    top 0
    left 0
    width 100%
    z-index 10
  .pageHeight
    z-index 5
    left 0
    right 0
    width 100%
    overflow-x hidden
    .heightContent
      height 6500px
  .bottomICP
    display flex
    flex-wrap wrap
    justify-content center
    position fixed
    padding 8px 1rem
    left 0
    bottom 0
    width 100%
    backdrop-filter blur(5px)
    font-size 12px
    z-index 30
    .copyright, .icp
      color $c4
      margin 0 10px
      line-height 20px
      text-decoration none
    .icp
      display flex
      .icp-icon
        margin-right 6px
        width 16px
        height 17.28px
    .copyright
      bottom 0
    .showText
      animation showText .5s ease forwards
    .hideText
      animation hideText .5s ease forwards

  @keyframes showText
    0%
      opacity 0
      transform translate3d(-50%,10px,0)
    100%
      opacity 1
      transform translate3d(-50%,24px,0)
  @keyframes hideText
    0%
      opacity 1
      transform translate3d(-50%,24px,0)
    100%
      opacity 0
      transform translate3d(-50%,10px,0)

@keyframes star1
  0% 
    opacity 0.3
  50%
    opacity 1
  100%
    opacity 0.3
@keyframes rotateStar
  0% 
    transform rotateZ(0deg)
  100% 
    transform rotateZ(360deg)
@keyframes sunRund
  0%
    opacity 1
  50%
    opacity 0.5
  100%
    opacity 1
@keyframes comet
  0%
    top -50vh
    left 150vh
  20%
    top 150vh
    left -50vh
  100%
    top 150vh
    left -50vh
@keyframes comet2
  0%
    top -50vh
    left 180vh
  20%
    top 180vh
    left -50vh
  100%
    top 180vh
    left -50vh
@keyframes comet3
  0%
    top -50vh
    left 200vh
  20%
    top 200vh
    left -50vh
  100%
    top 200vh
    left -50vh
@keyframes comet4
  0%
    top -50vh
    left 240vh
  20%
    top 240vh
    left -50vh
  100%
    top 240vh
    left -50vh
@keyframes comet5
  0%
    top -50vh
    left 100vh
  20%
    top 100vh
    left -50vh
  100%
    top 100vh
    left -50vh
</style>