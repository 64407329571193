<template>
  <div class="cx-text7 show">
    <div class="textBox">
      <flow-title class="flowTitle1" :title="$t('contact.contact')"  :size="pageWidth < 600 ? 28:46"></flow-title>
      <div class="list">
        <div class="row text1">
          <label>{{$t('contact.tel')}}</label>
          <span>18064034662</span>
          <span class="tip">{{$t('contact.vx')}}</span>
          <svg class="copy cText1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" :data-clipboard-text="'18064034662'" @click="clickCopy(1)">
            <g id="组_99552" data-name="组 99552" transform="translate(-884 -1052)">
              <path id="路径_54791" data-name="路径 54791" d="M22.1,13.1H19.647V2.457H9V0H22.1V13.1Z" transform="translate(879.895 1052)" fill="#1f996b"/>
              <path id="路径_54790" data-name="路径 54790" d="M14.743,14.743H0V0H14.743V14.742ZM3.686,6.962v.82H6.962v3.276h.82V7.781h3.276v-.82H7.781V3.686h-.82V6.962Z" transform="translate(884 1055.257)" fill="#34ffb3"/>
            </g>
          </svg>
        </div>
        <div class="row text1">
          <label>{{$t('contact.email')}}</label>
          <span>2225845427@qq.com</span>
          <svg class="copy cText2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" :data-clipboard-text="'2225845427@qq.com'" @click="clickCopy(2)">
            <g id="组_99552" data-name="组 99552" transform="translate(-884 -1052)">
              <path id="路径_54791" data-name="路径 54791" d="M22.1,13.1H19.647V2.457H9V0H22.1V13.1Z" transform="translate(879.895 1052)" fill="#1f996b"/>
              <path id="路径_54790" data-name="路径 54790" d="M14.743,14.743H0V0H14.743V14.742ZM3.686,6.962v.82H6.962v3.276h.82V7.781h3.276v-.82H7.781V3.686h-.82V6.962Z" transform="translate(884 1055.257)" fill="#34ffb3"/>
            </g>
          </svg>
        </div>
        <div class="row text2">
          <label>{{$t('contact.qq')}}</label>
          <span>2225845427</span>
          <svg class="copy cText3" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" :data-clipboard-text="'2225845427'" @click="clickCopy(3)">
            <g id="组_99552" data-name="组 99552" transform="translate(-884 -1052)">
              <path id="路径_54791" data-name="路径 54791" d="M22.1,13.1H19.647V2.457H9V0H22.1V13.1Z" transform="translate(879.895 1052)" fill="#1f996b"/>
              <path id="路径_54790" data-name="路径 54790" d="M14.743,14.743H0V0H14.743V14.742ZM3.686,6.962v.82H6.962v3.276h.82V7.781h3.276v-.82H7.781V3.686h-.82V6.962Z" transform="translate(884 1055.257)" fill="#34ffb3"/>
            </g>
          </svg>
        </div>
        <div class="row text2" @click="clickLink1()">
          <label>{{$t('contact.case')}}</label>
          <span class="taobao">http://124.220.201.73:9001/</span>
        </div>
        <!-- <div class="row text2" @click="clickLink()">
          <label>{{$t('contact.taobao')}}</label>
          <span class="taobao">https://shop167468620.taobao.com</span>
        </div> -->
      </div>
      <div class="vxqq">
        <div class="vx">
          <div class="text3">
            <img class="icon" alt="微信：18064034662" src="@assets/images/vx.svg">
          </div>
          <div class="circle">
            <img alt="微信：18064034662" src="@assets/images/circle.svg">
          </div>
          <div class="code">
            <img alt="微信：18064034662" src="@assets/images/vxcode.png">
          </div>
        </div>
        <div class="qq">
          <div class="text3">
            <img class="icon" alt="qq：2225845427" src="@assets/images/qq.svg">
          </div>
          <div class="circle">
            <img alt="qq：2225845427" src="@assets/images/circle.svg">
          </div>
          <div class="code">
            <img alt="qq：2225845427" src="@assets/images/qqcode.png">
          </div>
        </div>
      </div>
      <p class="copyText">{{$t('contact.copy')}}</p>
    </div>
  </div>
</template>
<script>
import flowTitle from '@/components/base/flow-title.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    clickCopy (idx) {      
      var clipboard = new this.Clipboard(`.cText${idx}`)
      clipboard.on('success', () => {
        let obj = $(`.cText${idx}`).offset()
        $('.copyText').attr('style', `top:${obj.top}px;left:${obj.left+9}px;`)
        $('.copyText').attr('class', 'copyText showText')
        setTimeout(() => {
          $('.copyText').attr('class', 'copyText hideText')
        }, 2000);
        clipboard.destroy()
      })
    },
    clickLink () {
      window.open('https://shop167468620.taobao.com')
    },
    clickLink1 () {
      window.open('http://124.220.201.73:9001/')
    },
  },
  components: {flowTitle}
}
</script>
<style scoped lang="stylus">
.cx-text7
  position absolute
  top 0
  width 100vw
  height 100vh
  overflow hidden
  .textBox
    position absolute
    right 10vw
    width 60vw
    height 100vh
    display flex
    justify-content center
    flex-direction column
  .list
    display flex
    flex-wrap wrap
    margin-top 46px
    z-index 10
    .row
      width 50%
      line-height 24px
      display flex
      color #fff
      font-size 18px
      margin-bottom 16px
      label
        width 62px
        margin-right 12px
      .tip
        color #B7B7B7
      .copy
        margin-left 24px
        cursor pointer
      .taobao
        color #34FFB3
        cursor pointer
  .vxqq
    display flex
    z-index 10
    .vx, .qq
      width 32px
      height 32px
      position relative
      margin 6px 60px 0 0
      border-radius 50%
      .icon
        width 100%
      .circle, .code
        position absolute
        display none
      .circle
        width 60px
        height 60px
        top -14px
        left -14px
        img
          width 100%
      .code
        border 1px solid #fff
        border-radius 10px
        padding 13px
        width 206px
        bottom calc(100% + 20px)
        left 50%
        transform translate3d(-50%, 0, 0)
        background-color rgba(255,255,255,0.2)
        backdrop-filter blur(5px)
        z-index 10
        img
          width 100%
          border-radius 8px
      &:hover 
        .icon
          animation scale .5s ease forwards
        .circle
          display block
          animation rotate 1s ease infinite
        .code
          display block
          background-color rgba(255,255,255,0.2)
          backdrop-filter blur(5px)
  .copyText
    position fixed
    font-size $f12
    color #34ffb3
    left 0
    opacity 0
  .bottomICP
    display flex
    flex-wrap wrap
    justify-content center
    position fixed
    padding 8px 1rem
    left 0
    bottom 0
    width 100%
    font-size 12px
    z-index 10
    background-color rgba(0,0,0,1)
    .copyright, .icp
      color $c4
      margin 0 10px
      line-height 20px
      text-decoration none
    .icp
      display flex
      .icp-icon
        margin-right 6px
        width 16px
        height 17.28px
    .copyright
      bottom 0
    .showText
      animation showText .5s ease forwards
    .hideText
      animation hideText .5s ease forwards

  @keyframes showText
    0%
      opacity 0
      transform translate3d(-50%,10px,0)
    100%
      opacity 1
      transform translate3d(-50%,24px,0)
  @keyframes hideText
    0%
      opacity 1
      transform translate3d(-50%,24px,0)
    100%
      opacity 0
      transform translate3d(-50%,10px,0)
  @keyframes rotate
    0%
      transform rotate(0deg)
    50%
      transform rotate(-90deg)
    100%
      transform rotate(0deg)
  @keyframes scale
    0%
      transform scale(1)
    10%
      transform scale(1.2)
    50%
      transform scale(0.8)
    100%
      transform scale(1)

.show
  display flex
  .flowTitle1, .text1, .text2, .text3
    opacity 0
  .flowTitle1
    animation textUp 0.8s 2s ease forwards
  .text1
    animation textUp 0.8s 2.2s ease forwards
  .text2
    animation textUp 0.8s 2.4s ease forwards
  .text3
    animation textUp 0.8s 2.6s ease forwards
.hide
  .flowTitle1, .text1, .text2, .text3
    opacity 0
.hideUp, .hideDown
  .flowTitle1, .text1, .text2, .text3
    opacity 1
.hideUp
  .flowTitle1
    animation hideUp1 1s 0s ease forwards
  .text1
    animation hideUp2 1s 0s ease forwards
  .text2
    animation hideUp3 1s 0s ease forwards
  .text3
    animation hideUp4 1s 0s ease forwards
.hideDown
  .flowTitle1
    animation hideDown1 1s 0s ease forwards
  .text1
    animation hideDown2 1s 0s ease forwards
  .text2
    animation hideDown3 1s 0s ease forwards
  .text3
    animation hideDown4 1s 0s ease forwards


@keyframes hideUp1
  0%
    opacity 1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp2
  0%
    opacity 0.8
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp3
  0%
    opacity 0.6
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp4
  0%
    opacity 0.4
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp5
  0%
    opacity 0.2
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
@keyframes hideUp6
  0%
    opacity 0.1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, 300px, 0)
    
@keyframes hideDown1
  0%
    opacity 0.1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown2
  0%
    opacity 0.2
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown3
  0%
    opacity 0.4
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown4
  0%
    opacity 0.6
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown5
  0%
    opacity 0.8
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@keyframes hideDown6
  0%
    opacity 1
    transform translate3d(0, 0, 0)
  100%
    opacity 0
    transform translate3d(0, -300px, 0)
@media screen and (max-width: 1600px)
  .cx-text7
    .textBox
      width 65vw
      .list
        .row
          font-size 16px
          .copy
            margin-left 12px
@media screen and (max-width: 1440px)
  .cx-text7
    .textBox
      width 70vw
      right 3vw
@media screen and (max-width: 1200px)
  .cx-text7
    .textBox
      width 85vw
      right 3vw
@media screen and (max-width: 900px)
  .cx-text7
    .textBox
      left 5vw
      right 5vw
      width 90vw
      .list
        margin-top 2rem
        .row
          width 100%
          line-height 1rem
          font-size 0.7rem
          label
            width 2.5rem
          .copy
            margin-left 0.5rem
      .vxqq
        width auto
        margin 0 auto
        justify-content center
        .vx, .qq
          margin 2rem 1rem 0 1rem
@media screen and (max-width: 600px)
  .cx-text7
    .textBox
      .vxqq
        position fixed
        width 100%
        left 0
        bottom 70px
</style>